@import 'app/variables';

.previewContainer {
    width: 100%;
    display: flex;
    position: relative;
}

.loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $neutralWhite;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fs {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed !important;
    z-index: 99999;
}

.previewDocContainer {
    width: 100%;
    display: flex;
    position: relative;
}

.preview {
    width: 100%;
    display: flex;
    position: relative;
    border: 1px solid $neutralGray4;

    iframe {
        border: none;
    }
}

.noResultsIcon {
    width: 40px;
    height: 30px;
}

.noResultsContainer {
    height: 100%;
}

.inVisibleIframe {
    width: 0;
    height: 0;
}

@media ($mediaSmall) {
    .preview {
        padding: 0;
    }
}
