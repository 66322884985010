@import 'app/variables';

.item {
    border-bottom: 1px solid $neutralGray4;
    margin-bottom: 24px;

    &:last-child {
        border-bottom: none;
        margin-bottom: 0;
    }
}
