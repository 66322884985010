@import 'app/variables';

.portal {
    width: 100%;
    margin-bottom: 40px;
    padding: 16px 0 24px 0;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    background: $neutralWhite;

    @media ($mediaSmall) {
        margin-bottom: 18px;
    }

    @media ($mediaExtraSmall) {
        padding: 12px 0 8px 0;
    }
}
