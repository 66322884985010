@import 'app/variables';
@import 'app/mixins';

.item {
    display: flex;
    flex-direction: column;
}

.header,
.unit,
.subject,
.extraInfo {
    display: flex;
    flex: 0 0 auto;
    min-height: 0;
    justify-content: flex-start;
}
.header,
.extraInfo {
    height: 16px;
    line-height: 16px;
}

.header {
    margin-bottom: 12px;
}

.unit {
    font-size: 14px;
    margin-bottom: 7px;
}

.subject {
    @include n-lines-text-overflow(20px, 2);

    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 7px;

    @media ($mediaSmall) {
        margin-bottom: 2px;
    }
}

.extraInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reply,
.status {
    margin-right: 8px;
}

.reply,
.status,
.reference {
    @include text-ellipsis;

    line-height: 18px;
}

.status {
    font-size: 10px;
    font-weight: 500;
    height: 16px;
    line-height: 18px;
    padding: 0 4px;
    border-radius: 4px;
    color: $neutralWhite;
    background: $secondaryActiveBlue;
}

.reference {
    @include text-ellipsis;

    font-size: 12px;
    color: $neutralGray2;
}

.date {
    @include text-ellipsis;

    color: $neutralGray2;
    font-size: 12px;
}

.hasAttachments {
    @include icon;

    background-image: url(~app/static/images/paperClip.svg);
}

.reply {
    @include icon;

    background-image: url(~app/static/images/reply.svg);
}

.isHighPriority {
    @include icon;

    background-image: url(~app/static/images/alert-circle.svg);
    margin-right: 8px;
}
