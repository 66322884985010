.pathArray {
    font-size: 14px;
    line-height: 20px;
    padding-top: 8px;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
}

.pathChevron {
    padding-right: 8px;
}

.path {
    padding-right: 8px;
}

.fullPath {
    display: inline-block;
}
