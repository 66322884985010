@import 'app/variables';

$size: 20px;

.backButton {
    min-width: $size !important;
    width: $size;
    height: $size;
    background-image: url(../../../static/images/back_arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &:hover {
        background-color: transparent;
    }

    @media ($mediaDesktop) {
        display: none !important;
    }
}
