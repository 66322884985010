@import 'app/variables';
@import 'app/mixins';

.trainingsListItem {
    display: flex;
    max-height: 112px;
    min-width: 0;
}

.thumbnail {
    width: 40px;
    height: 40px;
    margin-right: 16px;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
    padding-top: 2px;
    justify-content: space-between;
    min-width: 0;
}

.name {
    @include n-lines-text-overflow(20px, 2);

    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    flex-shrink: 0;
}

.extraInfo {
    font-size: 12px;
    line-height: 16px;
    color: $neutralGray2;
    display: flex;
    flex-direction: column;
}

.length {
    display: flex;
    align-items: baseline;
    margin-bottom: 4px;
}

.length span {
    @include text-ellipsis();
}

.length::before {
    content: '';
    width: 14px;
    height: 14px;
    display: block;
    flex-shrink: 0;
    margin-right: 4px;
    transform: translateY(15%);
    background: url(../../../../static/images/time-clock-circle.svg);
}

.fieldOfStudyWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
}

.fieldOfStudy {
    @include text-ellipsis();
}
