@import 'app/variables';
@import 'app/mixins';

.wave {
    background-color: $mainBackgroundColor;

    & ::after {
        background: linear-gradient(90deg, transparent, $neutralGray4, transparent);
    }
}
