@import 'app/variables';
@import 'app/mixins';

.description {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px 32px;
    padding-bottom: 0;
    font-size: 14px;
    overflow: auto;
    flex-shrink: 0;

    @media ($mediaSmall) {
        padding: 16px;
    }
}

.row {
    display: flex;
    align-items: baseline;
}

.locationIcon {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: 40px;
    background-position: center;
    position: absolute;
    top: -20px;
    left: -5px;
}

.properties {
    display: flex;
    border-bottom: 1px solid $neutralGray4;
    width: 100%;
    margin-bottom: 20px;
    flex-direction: column;
    flex-shrink: 0;

    span {
        margin-bottom: 16px;
    }

    &:last-child {
        border-bottom: none;
        margin-bottom: 0;
    }
}

.leftColumn {
    display: flex;
    width: 120px;
    flex-direction: column;
    color: $neutralGray2;
}

.rightColumn {
    display: flex;
    width: 70%;
    flex-direction: column;
    word-break: break-all;
    position: relative;

    span {
        @include n-lines-text-overflow(20px, 3);
    }
}

@media ($mediaSmall) {
    .leftColumn {
        width: 120px;
    }
}
