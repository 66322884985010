@import 'app/variables';

.searchOption {
    margin: 0;
    overflow: hidden;

    &:hover {
        background: $neutralGray4;
    }
}

.customCheckbox {
    opacity: 0;
    width: 0;
    height: 0;
}

.customCheckbox + label {
    display: inline-flex;
    user-select: none;
    cursor: pointer;
}

.customCheckbox + label::before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 15px;
    flex: 0 0 auto;
    border: 1px solid $neutralGray2;
    margin-right: 8px;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center;
}

.customCheckbox:checked + label::before {
    border-color: $primaryRoyalBlue;
    background-color: $primaryRoyalBlue;
    background-size: 8px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.customCheckbox:active + label::before {
    background-color: $filtersCheckboxActive;
    border-color: $filtersCheckboxActive;
}

.entry {
    padding: 8px;
    width: 100%;
}

.entryName {
    font-size: 12px;
    padding-top: 3px;
}
