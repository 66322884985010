@import 'app/variables';
@import 'app/mixins';

.item {
    display: flex;
}

.avatar {
    margin-right: 12px;
}

.avatarImage {
    width: 32px;
    height: 32px;
    font-size: 14px;
}

.info {
    flex-grow: 1;
    position: relative;
    overflow: hidden;
}

.name,
.type,
.category,
.submitionTotal {
    display: block;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    margin-bottom: 4px;
}

.type,
.category,
.submitionTotal {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.name {
    @include n-lines-text-overflow(20px, 1);

    color: $neutralBlack;
    font-weight: 500;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
}

.type {
    color: $neutralBlack;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-left: -2px;
    margin-right: -2px;

    & > * {
        margin: 0 2px;
    }

    .timeAgo {
        position: relative;
        color: $neutralGray2;
        font-size: 12px;
        line-height: 16px;
    }
}

.header {
    align-items: start;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 12px;
}

.docId {
    color: $primarySteelGray;
    background: $primarySteelGrayBg;
    font-size: 10px;
    line-height: 14px;
    padding: 4px 5px 0;
    border-radius: 4px;
    font-weight: 500;
}

.category,
.submitionTotal {
    color: $neutralGray2;
}
