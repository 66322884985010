.avatar {
    font-family: 'ADNOC Sans', sans-serif;
    font-weight: 400;
}
.avatarName {
    display: inline-block;
    padding-top: 2px;
}

.colorType1 {
    background: #d5f5e3;
    color: #39cf78;
}
.colorType2 {
    background: #e5ecff;
    color: #4f7cff;
}
.colorType3 {
    background: #fadbd8;
    color: #e74d3e;
}
.colorType4 {
    background: #fcf3cf;
    color: #dbb20e;
}
.colorType5 {
    background: #e2dcfb;
    color: #7850e9;
}
