@import 'app/variables';
@import 'app/mixins';

.documentPreviewInfo {
    display: flex;
    justify-content: left;
    flex-shrink: 0;
}

.left {
    min-width: 56px;
    width: 56px;
    margin-right: 24px;
    flex-shrink: 0;
    transition: 300ms;

    @media ($mediaExtraSmall) {
        margin-right: 16px;
    }
}

.right {
    flex-grow: 1;
    min-width: 0;
}

.mainInfo {
    display: flex;
    justify-content: space-between;

    @media ($mediaSmall) {
        flex-direction: column;
    }
}

.mainInfoLeft {
    min-width: 0;
    width: 100%;
}

.mainInfoRight,
.extraInfo {
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    color: $neutralGray2;
}

.mainInfoRight {
    flex-shrink: 0;

    @media ($mediaSmall) {
        display: none;
    }
}

.title {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    word-break: break-all;
    width: 100%;
    position: relative;
}

.fontSizeTitleChanged {
    font-size: 14px;
}

.documentName {
    @include text-ellipsis;

    margin-right: 20px;
    display: inline-block;
    width: 100%;
}

.extraInfo {
    margin-top: 8px;
}

.extraInfoDate {
    white-space: nowrap;
}

.actions {
    display: flex;
    flex-wrap: wrap;
    margin: 16px -16px -16px 80px;

    button,
    a {
        margin: 0 16px 16px 0;
        height: 15px;
        line-height: 20px;
        cursor: pointer;
        white-space: nowrap;
    }

    a {
        display: inline-flex;
        align-items: center;
    }

    @media ($mediaSmall) {
        margin-top: 8px;
    }
    @media ($mediaExtraSmall) {
        margin-left: 72px;
    }

    .copyTooltip {
        position: absolute;
        top: 20px;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        &.visible {
            visibility: visible;
            opacity: 1;
        }

        @media ($mediaSmall) {
            left: -20px;
        }
    }
}

.eyeIcon {
    width: 16px;
    height: 11px;
    margin-right: 7px;
}

.linkIcon {
    width: 18px;
    height: 17px;
    margin-right: 7px;
}

.collapseButton {
    cursor: pointer;
    margin-left: 14px;
}

.downloadIcon {
    width: 18px;
    height: 14px;
    margin-right: 7px;
}

.documentIcon {
    width: 100%;
}

.actionsText {
    font-weight: 500;

    @media ($mediaSmall) {
        display: none;
    }
}
.docIconCollapsed {
    width: 0;
    min-width: 0;
    margin-right: 0;
}

.fullPath {
    @include text-ellipsis();
    .path {
        @include text-ellipsis();
    }

    &:last-child {
        .pathChevron {
            padding-left: 0;
        }

        .path {
            padding-right: 0;
        }
    }
}

@media ($mediaSmall) {
    .pathArray {
        font-size: 14px;
    }

    .fullPath {
        /* hide all breadcrumbs except for the last two */
        &:nth-last-child(n + 3) {
            display: none;
        }

        /* replaced second last breadcrumb to fake text-ellipsis */
        &:nth-last-child(2) {
            overflow: visible;
            .path {
                font-size: 0;
                &::after {
                    content: '...';
                    font-size: 14px;
                }
            }
        }
    }
}

a:hover > .copyIcon path {
    stroke: none;
    fill: $primaryCoolBlue;
}
