@import 'app/variables';
@import 'app/mixins';

.header {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 16px;
}

.labelsContainer {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
}

.courseProvider {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-right: auto;
    margin-bottom: 8px;
}

.labels {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.courseType,
.courseLanguage {
    display: inline-block;
    padding: 1px 6px 0;
    border-radius: 4px;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    height: 16px;
    flex-shrink: 0;
    color: $neutralWhite;
}

.courseType {
    background-color: $secondaryActiveBlue;
    margin: 0 4px;
}

.courseLanguage {
    background-color: $secondaryPurple;
}

.title {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 11px;
}

.specification {
    display: flex;
    font-size: 14px;
    align-items: center;
}

.duration,
.courseCategory {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.duration {
    margin-left: 5px;
    margin-right: 16px;
}

.iconContainer {
    height: 21px;
}

.clockIcon {
    width: 16px;
    height: 16px;
    display: inline-block;
    background: url('../../../../static/images/time-clock-circle.svg') no-repeat;
    background-size: 16px;
}

.courseImageContainer {
    display: flex;
    width: 100%;
    height: 340px;
    position: relative;
    margin-top: 24px;
    margin-bottom: 28px;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.43);
    }
}

.courseImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.transparentButton {
    color: $neutralWhite;
    padding: 10px 27px 10px 27px;
    height: 40px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: 1px solid $neutralWhite;
    border-radius: 4px;
    text-decoration: none;
    z-index: 1;
    background: rgba(0, 0, 0, 0.3);
}

.blueButton {
    padding: 12px 17px 6px 17px;
    color: $neutralWhite;
    background-color: $primaryRoyalBlue;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
    margin-top: 16px;
}

.descriptionText {
    font-size: 14px;
    line-height: 20px;
    margin-top: 24px;
}

.imageContainer {
    margin: auto;
    transform: translateY(-50%);
}

.trainingsImage {
    background-image: url(../../../../static/images/trainings.svg);
    width: 100px;
    height: 103px;
    margin: auto;
    margin-bottom: 16px;
}

.noSelectedText {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $neutralGray1;
}

.skeleton {
    display: flex;
    justify-content: center;
}

@media ($mediaSmall) {
    .descriptionText {
        max-width: 100%;
        margin-top: 16px;
    }

    .courseImageContainer {
        height: 188px;
        margin-top: 0;
    }
}
