.fuelRevenue {
    background-color: rgba(#e32726, 0.1);

    &::before {
        content: url('../../../../../static/images/gasLoad.svg');
    }
}

.cStoreRevenue {
    background-color: rgba(#0075c9, 0.1);

    &::before {
        content: url('../../../../../static/images/shoppingCart.svg');
    }
}

.carWashRevenue {
    background-color: rgba(#7536cb, 0.1);

    &::before {
        content: url('../../../../../static/images/carRepairWash.svg');
    }
}

.lubeRevenue {
    background-color: rgba(#005871, 0.1);

    &::before {
        content: url('../../../../../static/images/pollutionBarrel.svg');
    }
}

.lpgRevenue {
    background-color: rgba(#ee7623, 0.1);

    &::before {
        content: url('../../../../../static/images/fossilEnergyGasLantern.svg');
    }
}

.complaints {
    background-color: rgba(#1859a9, 0.1);

    &::before {
        content: url('../../../../../static/images/priceIcon.svg');
    }
}
