@import 'app/variables';

.root {
    display: flex;
    justify-content: space-between;
    margin-left: -3px;
    margin-right: -3px;

    .btn {
        height: 16px;
        padding: 3px 4px 0;
        box-sizing: border-box;
        border-radius: 14px;
        background: $primaryRoyalBlue;
        color: $neutralWhite;
        border: 0;
        outline: 0;
        cursor: pointer;
        font: 400 10px/1 'ADNOC Sans', sans-serif;
        flex-grow: 1;
        margin: 0 3px;
        transition: opacity 0.2s ease-in-out;

        &:not(.active) {
            opacity: 0.25;
        }
    }

    @media ($mediaSmall) {
        margin-left: -1px;
        margin-right: -1px;

        .btn {
            margin: 0 1px;
        }
    }
}
