@import 'app/variables';

.title {
    font-weight: bold;
    color: $neutralGray1;
    margin-bottom: 10px;

    span {
        color: $SecondaryRed;
        width: 16px;
        display: inline-block;
    }
}

.description {
    color: $neutralGray2;
    margin-bottom: 16px;
    white-space: nowrap;

    span {
        color: $neutralGray1;
    }

    &:last-child {
        margin-bottom: 0;
    }
}
