@import 'app/variables';

.root {
    font-size: 0;
    line-height: 0;
    text-decoration: none;
    color: $primaryCoolBlue;
    white-space: nowrap;

    img {
        font-size: 0;
        height: 16px;
        position: relative;
        margin-top: -2px;
    }

    & > * {
        vertical-align: top;
    }

    span {
        display: inline-block;
        margin-left: 5px;
        font-size: 12px;
        line-height: 16px;
    }
}
