@import 'app/variables';
@import 'app/mixins';

.wrapper {
    display: flex;
    align-items: center;

    @media ($mediaExtraSmall) {
        justify-content: center;
        flex-wrap: wrap;
    }
}

.chart {
    width: 40%;
    flex: 0 0 auto;
    margin-right: 10px;
    position: relative;

    @media ($mediaExtraSmall) {
        max-width: 190px;
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
    }
}

.color {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
}

.label {
    font-size: 12px;
    white-space: nowrap;
    padding-bottom: 15px;
    padding-right: 15px;
}

.value {
    font-weight: bold;
    font-size: 12px;
    text-align: right;
    white-space: nowrap;
}

.legend {
    width: 60%;

    @media ($mediaExtraSmall) {
        width: 100%;
    }
}

.legendTable {
    display: table;
    margin: 0 auto;

    .label,
    .value {
        display: table-cell;
    }

    & > div {
        display: table-row;

        &:last-child {
            .value,
            .label {
                padding-bottom: 0;
            }
        }
    }
}
