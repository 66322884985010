@import 'app/variables';
@import 'app/mixins';

.noResults {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
}

.filterButtonsContainer {
    position: absolute;
    top: 24px;
    left: 20px;
}

.filterButton {
    grid-area: topLeft;
    margin-bottom: 8px;
}

.showFiltersButton {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    cursor: pointer;
    color: $primaryRoyalBlue;
    margin-bottom: 6px;
    grid-area: topLeft;

    @media ($mediaSmall) {
        display: none;
    }
}

.promptText {
    margin-top: 15px;
    color: $neutralBlack;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 0 35px;
    text-align: center;
}

.promptTextLight {
    color: $neutralGray1;
}

.helperText {
    margin-top: 10px;
    color: $neutralGray2;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
}

.promptTextLogo {
    height: 100px;
}

.image {
    background-repeat: no-repeat;
    background-position: center;
    max-height: 100%;
    margin: 0 auto;
    display: block;
}

@media ($mediaSmall) {
    .promptText {
        font-size: 16px;
    }
}
