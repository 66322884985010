@import 'app/variables';
@import 'app/mixins';

.trendHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .trendTitle {
        font: 500 16px/24px 'ADNOC Sans', sans-serif;
        margin: 0;
    }
}

.center {
    justify-content: center;
}

.button {
    padding: 20px;
}

.buttonForSmallWidth {
    padding: 5px;
}
