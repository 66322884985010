@import 'app/variables';

.tabsContainer {
    width: 100%;
    background: $neutralWhite;
    padding: 0 0 16px 0;
    position: relative;
}

.tabPanelActive {
    position: static;
}

.tabsMuiAppBarRoot {
    padding: 0;
    margin: 0 16px;
    width: auto;
}

.previewPanel {
    display: flex;
    width: 100%;
    height: 100%;
}

.errorExtraStyles {
    position: relative;
    height: calc(100% - 1rem);
}

.attachmentPreviewContainer {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed !important;
    z-index: 99999;
}

.loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $neutralWhite;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageContainer {
    margin: auto;
    transform: translateY(-50%);
}

.image {
    background-image: url(~app/static/images/pleaseChooseCorrespondence.svg);
    background-repeat: no-repeat;
    width: 100px;
    height: 140px;
    margin: auto auto 16px auto;
}

.noSelectedText {
    text-align: center;
}

.noPreviewErrorImage {
    width: 100px;
    height: 140px;
}

.correspondenceContainer {
    height: 100%;
    padding-bottom: 34px !important;
    position: relative;
}

.footer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 24px;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: $neutralGray6;
    box-sizing: border-box;
    border-top: 1px solid $neutralGray4;

    @media ($mediaSmall) {
        padding: 8px 16px;
        height: 48px;
    }

    .button {
        font-family: 'ADNOC Sans', Arial, Helvetica, sans-serif;
        padding: 7px 15px;
        background-color: $neutralWhite;
        color: $primaryRoyalBlue;
        display: inline-flex;
        text-decoration: none;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        border: 1px solid $primaryRoyalBlue;
        border-radius: 5px;
        margin-left: 16px;
        align-items: center;
        flex-shrink: 0;
        outline: none;

        @media ($mediaSmall) {
            padding: 4px 8px;
            margin-left: 8px;
        }

        &:hover {
            border: 1px solid $primaryCoolBlue;
            background-color: $neutralWhite;
        }

        &:focus {
            border: 1px solid $primaryRoyalBlue;
            background-color: $neutralGray6;
        }

        .text {
            margin-left: 8px;
            margin-top: 4px;
            line-height: 16px;
        }
    }
}
