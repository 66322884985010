.Header {
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: content-box !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 36px;
    background: #f8f9fa;
    z-index: 101;
    user-select: none;
    border-bottom: 1px solid #cfd4da;

    &Items {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        position: relative;
        padding: 0 4px;
        user-select: none;
        justify-content: space-between;
    }
}

.Button {
    padding: 0;
    border: none;
    background-color: #0000;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    color: #485056;
    outline: none;

    img {
        height: 24px;
        width: 24px;
    }
}
