.skeleton {
    display: flex;
    padding: 16px 0;
    width: 100%;
    box-sizing: border-box;
    height: 116px;
}

.thumbnail {
    flex: 0 0 auto;
    margin-right: 16px;
}
.info {
    flex-grow: 1;
}

.textInfo {
    margin-bottom: 8px;
}
