.cards {
    margin-bottom: 24px;

    .cardsGrid {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 -8px -16px -8px;
    }

    .cardBody {
        display: flex;
        flex-direction: column;
        height: 100%;

        .headline {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            margin: 0;
            margin-bottom: 16px;

            span {
                font-size: 12px;
                line-height: 16px;
            }
        }

        .content {
            flex-grow: 1;
        }

        .btnGroup {
            margin-top: 16px;
        }
    }
}
