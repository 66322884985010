@import 'app/variables';

.skeleton {
    padding: 16px 24px 20px;

    @media ($mediaSmall) {
        padding: 15px;
    }
}

.info {
    margin-bottom: 8px;
}

.info:last-child {
    margin-bottom: 0;
}
