@import 'app/variables';

$searchButtonHeight: 32px;
$optionsMenuMargin: 4px;

@mixin icon {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    width: 16px;
    height: 16px;
}

.search {
    height: $searchButtonHeight;
    width: 100%;
    position: relative;
    overflow: visible;
}

.searchMenuButton {
    height: 20px;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    padding: 0;
    border: 0 none transparent;
    background: transparent;
    font-size: 14px;
    font-family: 'ADNOC Sans', sans-serif;
    outline: none;
}

.searchMenuButtonText {
    padding-top: 3px;
}

.searchMenuButtonIcon {
    display: inline-flex;
    width: 18px;
    height: 18px;
    align-items: center;
    justify-content: center;
    margin-right: 16px;

    &::after {
        content: '';
        width: 20px;
        height: 20px;
        background: url(~app/static/images/filtersSearchButtonIcon.svg) no-repeat;
        background-size: contain;
    }
}

.searchInput {
    position: relative;
    width: 100%;
    height: 32px;
    padding: 9px 30px 7px;
    border-radius: 32px;
    border: 1px solid $primaryRoyalBlue;
    font-size: 14px;
    font-family: 'ADNOC Sans', sans-serif;
    outline: none;
}

.searchInputIcon {
    @include icon();

    left: 8px;
    background-image: url(~app/static/images/magnifyingGlassFilters.svg);
}

.searchClearButton {
    @include icon();

    right: 8px;
    border: 0 none transparent;
    border-radius: 50%;
    background-color: $neutralGray5;
    background-image: url(~app/static/images/times.svg);
    cursor: pointer;
    outline: none;
}

.optionsMenu {
    list-style: none;
    width: 100%;
    max-height: 144px;
    overflow: auto;
    padding: 0;
    position: absolute;
    z-index: 1;
    border-radius: 5px;
    box-shadow: 0 10px 22px 0 rgba(0, 0, 0, 0.2);
    background: $neutralWhite;
}

.optionsMenuBottom {
    top: $searchButtonHeight + $optionsMenuMargin;
}

.optionsMenuTop {
    bottom: $searchButtonHeight + $optionsMenuMargin;
}
