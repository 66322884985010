@import 'app/variables';

.customProperty {
    justify-content: start;
}

.textFieldFilter {
    margin-bottom: 24px;
}

.checkbox {
    opacity: 0;
    width: 0;
    height: 0;
    display: block;
}

.checkbox + .checkboxMask {
    display: inline-block;
    user-select: none;
}

.checkbox + .checkboxMask::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 15px;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 16px;
    border: 1px solid $neutralGray2;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

.checkbox:checked + .checkboxMask::before {
    border-color: $primaryRoyalBlue;
    background-color: $primaryRoyalBlue;
    background-size: 8px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.checkbox:active + .checkboxMask::before {
    background-color: $filtersCheckboxActive;
    border-color: $filtersCheckboxActive;
}
