.loader {
    @-webkit-keyframes oh-rotating /* Safari and Chrome */ {
        from {
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes oh-rotating {
        from {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    .oh-rotating {
        -webkit-animation: oh-rotating 800ms linear infinite;
        -moz-animation: oh-rotating 800ms linear infinite;
        -ms-animation: oh-rotating 800ms linear infinite;
        -o-animation: oh-rotating 800ms linear infinite;
        animation: oh-rotating 800ms linear infinite;
    }
}
