@import 'app/variables';

.button {
    background-color: $primaryRoyalBlue;
    color: $neutralWhite;
    border-radius: 5px;
    padding: 10px 24px;
    max-width: 108px;
    max-height: 40px;
    outline: unset;
    border: unset;
    cursor: pointer;

    &:hover {
        background-color: $primaryCoolBlue;
    }
}
