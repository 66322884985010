@import 'app/variables';

.container {
    display: flex;
    margin-top: 9px;
}

.avatar {
    width: 45px;
    height: 45px;
    margin-right: 14px;
}

.name {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.title {
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px;
    color: $neutralGray3;
}

.companyName {
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px;
}

.contact {
    display: flex;
    margin-top: 16px;

    a {
        margin-right: 16px;
    }
}
