.preview {
    overflow: hidden auto;
    flex-grow: 1;
}

.container {
    flex-grow: 1;
    padding: 26px 32px;
    overflow: auto;
}

.imageContainer {
    margin: auto;
    transform: translateY(-50%);
}

.image {
    background-image: url(~app/static/images/people.svg);
    width: 100px;
    height: 125px;
    margin: auto auto 16px auto;
}

.noSelectedText {
    text-align: center;
}

.skeleton {
    display: flex;
    justify-content: center;
}
