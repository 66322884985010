@import 'app/variables';

.root {
    background: $neutralWhite;
    border-bottom: 1px solid $neutralGray4;
    color: $neutralBlack;
    box-shadow: none;
    height: $tabsTopBarRootHeight;
    width: 100%;
    font-family: 'ADNOC Sans', sans-serif;
    position: relative;
    padding-left: 32px;
}

.tabsRoot {
    min-width: 0;
    min-height: 0;
    font-family: 'ADNOC Sans', sans-serif;
}

.tabRoot {
    min-width: 0;
    min-height: 0;
    padding: 10px 16px 5px;
    font-family: 'ADNOC Sans', sans-serif;
    opacity: 1;
    font-weight: normal;
}

.indicator {
    background-color: $primaryCoolBlue;
}

.wrapper {
    text-transform: none;
    font-size: 14px;
    line-height: 24px;
}

.selected {
    font-weight: 500;
}

.textColorInherit {
    text-transform: none;
    letter-spacing: 0.05px;
}

.scrollButton {
    position: absolute;
    height: 100%;
    background-color: $neutralWhite;
    opacity: 1;
    z-index: 100;

    &:first-child {
        left: 0;
    }

    &:last-child {
        right: 0;
    }
}
