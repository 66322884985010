@import 'app/variables';
@import 'app/mixins';

.subtitleWrapper {
    font-size: 0;
}
.subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $neutralGray2;
}

.title {
    @include n-lines-text-overflow(20px, 2);

    font-weight: 500;
    margin: 4px 0;
    font-size: 14px;
}
