@import 'app/variables';
@import 'app/mixins';

.header {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    line-height: 24px;

    .title {
        font-size: 16px;
    }

    .link {
        font-size: 14px;
    }
}

.detailsContent {
    display: table;
    margin: 24px 0 0;

    .row {
        display: table-row;
        font: 400 14px/20px 'ADNOC Sans', sans-serif;

        .label,
        .value {
            display: table-cell;
            text-align: start;
            vertical-align: top;
        }

        .label {
            width: 40%;
            word-break: break-word;
            padding-right: 24px;
            color: $neutralGray2;
        }

        .value {
            width: 60%;
        }

        &:not(:last-child) .label {
            padding-bottom: 16px;
        }
    }

    .rating {
        display: none;

        @media ($mediaExtraSmall) {
            display: table-row;
        }
    }

    .statusLabel {
        font-size: 10px;
        border-radius: 4px;
        padding: 0.5px 4px;
    }

    .active {
        color: $SecondaryGreen;
        background-color: rgba(0, 117, 74, 0.1);
    }

    .closed {
        color: red;
        background-color: rgba(169, 52, 58, 0.1);
    }

    .managerInfo {
        display: flex;

        .managerAvatar {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: 8px;
        }
    }

    .managerContacts {
        margin-top: 16px;

        .contactsIcon {
            margin-right: 21px;
        }

        .phoneIcon {
            position: relative;
            top: 3px;
            //margin-right: 21px;
        }
    }
}
