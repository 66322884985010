@import 'app/variables';
@import 'app/mixins';

.filterPanel {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    font-size: 14px;
}

.header {
    font-weight: 500;
    font-size: 16px;
    padding: 24px 24px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.switch {
    &Container {
        @extend .header;

        padding: 0 0 16px;
    }

    &Label {
        font-size: 14px;
        margin-left: 12px;
    }

    &LabelRoot {
        margin: 0;
    }

    &HelperText {
        font-size: 12px;
        font-family: 'ADNOC Sans', sans-serif;
        margin-top: 1rem;
    }
}

.cancelButton {
    border: 0 none;
    width: 12px;
    height: 16px;
    cursor: pointer;
    outline: none;
    padding: 0;
    background: url(../../../../static/images/close.svg) no-repeat;
    background-size: 12px;
}

.clearAll,
.applyButton {
    cursor: pointer;
    height: 32px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.clearAll {
    padding: 6px 0;
    color: $primaryRoyalBlue;
    margin-bottom: -5px;

    &:hover {
        color: $primaryCoolBlue;
    }

    &.clearAllDisabled {
        color: $darkGray;
        pointer-events: none;
    }
}

.applyButton {
    padding: 6px 16px;
    color: $neutralWhite;
    background-color: $primaryRoyalBlue;
    border: none;
    outline: none;
    border-radius: 5px;

    &:hover {
        background-color: $primaryCoolBlue;
    }
}

.applyButtonDisabled {
    background-color: $neutralGray4;
    cursor: auto;
    color: $neutralGray3;

    &:hover {
        background-color: $neutralGray4;
    }
}

.headerActions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.hideFiltersAction {
    font-size: 14px;
    display: flex;
    cursor: pointer;
    color: $primaryRoyalBlue;
}

.footer {
    display: flex;
    justify-content: space-between;
    height: 56px;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    align-items: center;
    background-color: $neutralGray5;
    flex: 0 0 auto;
}

.property {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    margin-bottom: 16px;
    font-weight: 500;
    cursor: pointer;
}

.filtersList {
    list-style: none;
    cursor: pointer;
    padding: 0;
    margin: 0 0 24px 0;
}

.container {
    min-height: 0;
    overflow: auto;
    margin: 0;
    flex-grow: 1;
    padding: 0 19px 0 24px;
}

.skeletons {
    padding: 24px 32px;
}

.headerSkeleton {
    margin-bottom: 20px;
}

.filterNameSkeleton {
    margin-bottom: 8px;
}

.filterSkeleton {
    margin-bottom: 16px;
}

.datePickerFilter {
    margin-bottom: 24px;
}

.datePickerEntryLabel {
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    padding-top: 8px;
    padding-bottom: 4px;
    display: inline-block;
}

.datePickerEntry:first-of-type {
    .datePickerEntryLabel {
        padding-top: 0;
    }
}

@media ($mediaSmall) {
    .header {
        padding: 16px 16px 24px;
    }

    .container {
        padding-left: 16px;
        padding-right: 10px;
    }
}
