@import 'app/variables';

.tabComponent {
    padding: 16px 24px 24px 24px;
    flex-grow: 1;
}

.status {
    display: inline-block;
    font: normal 10px/13px 'ADNOC Sans', sans-serif;
    padding: 2px 4px 0;
    border-radius: 4px;
    background: rgba(8, 155, 215, 0.15);
    color: #089bd7;
}

.tablePaginationContainer {
    display: flex;
    justify-content: space-between;
}

.paginationBtn {
    cursor: pointer;
    font: 500 14px/20px 'ADNOC Sans', sans-serif;
    color: $primaryRoyalBlue;
    border: 0;
    background: transparent;
    outline: 0;
    padding: 5px 16px;
    margin-top: 5px;
    transition: opacity 0.2s ease-in-out;

    &:hover {
        color: $primaryCoolBlue;
    }

    &:disabled {
        opacity: 0;
        pointer-events: none;
    }

    span {
        vertical-align: top;
        display: inline-block;
        padding-top: 1px;
    }
}

.icon {
    font-size: 20px;
    vertical-align: top;
}
