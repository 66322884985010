@import 'app/variables';

.redux-toastr {
    pointer-events: none;

    .top-right {
        width: 100vw;
    }

    .toastr {
        width: 336px;
        min-height: 50px;
        padding: 14px 0;
        margin: 10px 14px 10px auto;
        opacity: 1;
        font: 500 14px/20px 'ADNOC Sans', sans-serif;
        box-shadow: 0 2px 24px rgba(45, 45, 45, 0.02), 0 4px 44px rgba(45, 45, 45, 0.06);
        pointer-events: auto;

        .rrt-left-container {
            width: 40px;
            text-align: start;

            .rrt-holder {
                width: 100%;
                height: 100%;
                top: 16.5px;
                left: 16.5px;
                margin: 0;
                line-height: 1em;
            }
        }

        .rrt-middle-container {
            width: auto;
            margin: 2px 46px 0 40px;
            padding: 0;
        }

        .rrt-right-container {
            width: auto;

            .close-toastr {
                width: 46px;
                text-align: center;
                opacity: 1;
                font-size: 28px;
                top: -2px;

                span {
                    position: relative;
                    top: 14px;
                    font-size: 20px;
                    color: #9d9fa0;
                }
            }
        }

        &.webView {
            transform: translateY(108px);
        }

        &.mobile {
            @media ($mediaSmall) {
                width: auto;
                position: static;
                transform: translateY(90px);
                margin-right: 0;
            }
        }

        &.downloadInfo {
            background: $neutralGray5;
            border: 1px solid $primaryCoolBlue;
            color: $primaryCoolBlue;
        }

        &.downloadError {
            background: $neutralRed;
            border: 1px solid $SecondaryRed;
            color: $SecondaryRed;
        }

        &:hover {
            box-shadow: 0 2px 24px rgba(45, 45, 45, 0.02), 0 4px 44px rgba(45, 45, 45, 0.06) !important;
        }
    }
}
