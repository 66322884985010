@import 'app/variables';

.supplierInfo {
    .supplierInfoTitle {
        font: 500 16px/24px 'ADNOC Sans', sans-serif;
        margin-bottom: 24px;
    }
    .panel {
        display: grid;
        justify-content: space-between;
        grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
        gap: 30px 90px;

        @media (min-width: 1440px) {
            grid-template-columns: repeat(2, 332px);
        }
        @media ($mediaSmall) {
            grid-template-columns: 1fr;
        }

        .left {
            gap: 0 5px;

            .icon {
                width: 56px;
                height: 56px;
                margin: 0 auto 8px;
                border-radius: 50%;
                background: $primaryCoolBlue center no-repeat;
            }
            .iconContractor {
                background-image: url(../../../../../../static/images/multiple_neutral.svg);
            }
            .iconVendor {
                background-image: url(../../../../../../static/images/settingIcon.svg);
            }
            .iconManufacturer {
                background-image: url(../../../../../../static/images/water_dam.svg);
            }

            .statuses {
                text-align: center;
                .status {
                    width: min-content;
                    font: normal 10px/13px 'ADNOC Sans', sans-serif;
                    padding: 2px 4px 0;
                    border-radius: 4px;
                    background: rgba(8, 155, 215, 0.15);
                    color: #089bd7;
                    margin: 4px auto;
                }

                .activeStatus {
                    background: rgba(0, 117, 74, 0.15);
                    color: #00754a;
                    white-space: nowrap;
                }
            }
        }
        .right {
            gap: 20px 5px;

            .supplierItem {
                width: 90px;
            }

            .avatarContainer {
                position: relative;
                padding: 4px 4px 4px 0;
                margin-left: 4px;
                width: 52px;

                .count {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: $primaryCoolBlue;
                    font: normal 10px/20px 'ADNOC Sans', sans-serif;
                    color: $neutralWhite;
                    text-align: center;
                    white-space: nowrap;
                }
                .avatar {
                    width: 48px;
                    height: 48px;

                    &.avatarDisabled {
                        color: #fff;
                        background-color: #d4d2cf;
                    }
                }
            }
            .avatarTitle {
                font: normal 13px/20px 'ADNOC Sans', sans-serif;
                text-align: center;
            }

            .alignCenter {
                margin: 0 auto;
                width: min-content;
            }
        }

        .left,
        .right {
            display: grid;
            justify-content: space-between;
            grid-template-columns: repeat(3, 90px);
        }
    }

    .hr {
        margin: 16px 0;
        height: 1px;
        background-color: $neutralGray4;
    }

    .tableTitle {
        font: 500 14px/20px 'ADNOC Sans', sans-serif;
        margin-bottom: 12px;
    }
}
