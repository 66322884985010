@import 'app/variables';

.oneColumnLayout {
    width: 100%;
    overflow-y: auto;
    background: $mainBackgroundColor;
}

.content {
    width: 100%;
    max-width: 697px;
    margin-top: 40px;
    margin-left: 136px;

    @media (max-width: 1000px) {
        margin: 50px auto;
    }

    @media ($mediaSmall) {
        margin: 0;
        max-width: 100%;
        padding: 16px 20px;
    }
}
