@import 'app/variables';
@import 'app/mixins';

.header {
    display: flex;
    justify-content: space-between;

    .title,
    .subtitle {
        font-size: 12px;
        line-height: 16px;
    }

    .title {
        font-weight: 700;
        color: $neutralBlack;
        margin: 0 0 4px;
    }

    .subtitle {
        font-weight: normal;
        color: #4d5156;
        opacity: 0.7;
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        right: 16px;
        top: 16px;
    }
}

.label,
.currency {
    font-weight: normal;
    color: #4d5156;
    opacity: 0.7;
}

.label {
    font-size: 12px;
    line-height: 16px;
}

.total {
    font-size: 36px;
    font-weight: normal;
    margin: 12px 0;
}

.currency {
    font-size: 16px;
    line-height: 24px;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.value {
    font-size: 14px;
    line-height: 20px;
}
