@import 'app/variables';

.tooltip {
    position: relative;
    padding: 8px;
    background: $neutralWhite;
    color: $neutralBlack;
    z-index: 1200;
    font-size: 12px;
    line-height: 16px;
    box-shadow: 1px 7px 15px 0 rgba(0, 0, 0, 0.13);
    &::before {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        width: 0;
        height: 0;
        margin: 0 auto;
        border-style: solid;
        border-width: 0 5.65px 5.65px 5.65px;
        border-color: transparent transparent $neutralWhite transparent;
    }
}
