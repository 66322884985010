@import 'app/variables';
@import 'app/mixins';

.tableScrollWrapper {
    overflow: auto;
    max-height: 237px;
}

.table {
    border-collapse: collapse;
    width: 100%;

    th {
        padding: 8px 8px 7px;
        background-color: $neutralGray6;
        font: 500 12px/16px 'ADNOC Sans', sans-serif;
        position: sticky;
        top: 0;
        white-space: nowrap;
    }

    td {
        padding: 10px 8px;
        font: 400 14px/20px 'ADNOC Sans', sans-serif;
        white-space: nowrap;
        vertical-align: top;

        .textValue {
            min-width: 120px;
            white-space: normal;
            @include n-lines-text-overflow(20px, 3);
        }
    }

    th,
    td {
        text-align: left;
        border-bottom: 1px solid $neutralGray4;

        &:first-child {
            padding-left: 16px;
        }
        &:last-child {
            padding-right: 16px;
            text-align: right;
        }
    }
}
