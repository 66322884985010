@import 'app/variables';

.title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
}

.riskItem {
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
    }
}

.detailsContainer {
    display: flex;
    margin-bottom: 8px;
}

.details {
    display: flex;
    align-items: baseline;
    margin-right: 40px;
}

.label {
    font-size: 14px;
    line-height: 16px;
    color: $neutralGray2;
    margin-right: 5px;
}

.value {
    font-size: 14px;
    line-height: 20px;
}

.small {
    .detailsContainer {
        display: block;
    }
}
