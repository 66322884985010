@import 'app/variables';
@import 'app/mixins';

.header {
    display: flex;
    position: relative;
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 16px;
    justify-content: space-between;
}

.date {
    color: $neutralGray2;
}

.title {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
}
.linkWrapper {
    margin-bottom: 16px;

    a {
        font: 400 14px/20px 'ADNOC Sans', sans-serif;
        color: $primaryCoolBlue;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

.image {
    display: block;
    border-radius: 8px;
    object-fit: cover;
}
.image,
.videoplayer {
    max-width: 100%;
    height: 340px;
    margin: 0 auto;
}

.text {
    font-size: 14px;
    line-height: 20px;
    margin-top: 24px;
}

.imageContainer {
    margin: auto;
    transform: translateY(-50%);
}

.newsImage {
    background-image: url(../../../../static/images/news.svg);
    background-repeat: no-repeat;
    width: 100px;
    height: 103px;
    margin: auto auto 16px;
}

.noSelectedText {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $neutralGray1;
}

.skeleton {
    display: flex;
    justify-content: center;
}

.videoContainer {
    margin-top: 24px;
}
