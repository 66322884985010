@import 'app/variables';

.container {
    position: relative;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
}

.sorting {
    display: inline-flex;
    align-items: baseline;
}

.selectMenu {
    font-size: 14px;
    font-weight: 500;
    font-family: 'ADNOC Sans', sans-serif;

    & :focus {
        background-color: $neutralWhite;
    }
}

.selectRoot {
    padding-bottom: 0;
    padding-top: 3px;

    @media ($mediaSmall) {
        padding-top: 8px;
    }
}

.label {
    margin-right: 4px;
}

.menuItem {
    font-size: 14px;
    font-family: 'ADNOC Sans', sans-serif;
    padding-top: 10px;

    &:focus {
        background-color: $neutralWhite;
    }
}

.icon {
    fill: $neutralBlack;
    width: 19px;
    height: 19px;
    right: 5px;
    top: 0;

    @media ($mediaSmall) {
        top: 5px;
    }
}
