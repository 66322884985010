@import 'app/variables';
@import 'app/mixins';

.item {
    display: flex;
    flex-direction: row;
}

.thumbnail {
    margin-right: 16px;
}

.author,
.title,
.date {
    display: flex;
    flex: 0 0 auto;
    min-height: 0;
    justify-content: flex-start;
}

.author {
    font-size: 12px;
}

.title {
    @include n-lines-text-overflow(20px, 2);

    font-size: 14px;
    font-weight: 500;
    margin: 4px 0;
}

.date {
    color: $neutralGray2;
    font-size: 12px;
}
