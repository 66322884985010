@import 'app/mixins';
@import 'app/variables';

.list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 0;
    min-height: 0;
    max-height: 100%;
    overflow: auto;
}

.itemContainer {
    padding: 16px 0;
}

.item {
    outline: none;
    padding: 0 24px;
    cursor: pointer;
}

.active {
    background: $neutralGray6;
}

.noPaddings {
    padding: 0;
}

.dividers {
    border-top: 1px solid $neutralGray4;

    .active &,
    .active + li &,
    li:first-child & {
        border-color: transparent;
    }
}

@media ($mediaSmall) {
    .item {
        padding: 0 16px;
    }
}
