@import 'app/variables';
@import 'app/mixins';

.title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    h2 {
        font-size: 18px;
        font-weight: 500;
        margin: 0 0 20px;

        @media ($mediaSmall) {
            margin-bottom: 16px;
            width: 100%;
            flex-grow: 1;
        }
    }

    @media ($mediaSmall) {
        flex-wrap: wrap;
        & > * {
            margin-bottom: 16px;
        }
    }
}

.container {
    h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 16px 0;
    }
}

.itemContainer {
    background: $neutralWhite;
    box-shadow: 0 2px 24px rgba(45, 45, 45, 0.02), 0 4px 44px rgba(45, 45, 45, 0.06);
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;

    @media ($mediaExtraSmall) {
        padding: 16px;
    }
}

.containerHeader {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 0 16px -8px;
    & > *,
    & > h3 {
        margin: 0 0 8px 8px;
    }
}

.containerBody {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cardsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -8px 8px -8px;
}

.cardsContainer {
    background: $neutralWhite;
    box-shadow: 0 2px 24px rgba(45, 45, 45, 0.02), 0 4px 44px rgba(45, 45, 45, 0.06);
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    margin-left: 8px;
    margin-right: 8px;
    max-width: 312px;
    width: 100%;

    @media ($mediaExtraSmall) {
        max-width: none;
    }
}

.cardsContainerWidget {
    max-width: none;
    flex: 1;
}

.fullWidth {
    flex-basis: 100%;
}

.twoColumns {
    display: flex;
    flex-direction: row;
    margin: 0 -8px;

    & > div {
        margin-left: 8px;
        margin-right: 8px;
        width: calc(50% - 16px);
    }

    .medium & {
        display: flex;
        flex-direction: column;
        margin: 0;

        & > div {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
        }
    }
}

@media ($mediaSmall) {
    .container {
        padding: 16px;
    }
}

.salesBreakdownTable td:nth-child(2) {
    width: 100%;
    padding-right: 24px;
    text-align: right;

    @media ($mediaSmall) {
        width: auto;
        padding-right: 8px;
    }
}

.salesBreakdownTable {
    th:first-child,
    td:first-child {
        width: 100% !important;
    }
}
