@import 'app/variables';
@import 'app/mixins';

.container {
    flex-grow: 1;
    overflow: auto;
    border: none;
}

.imageContainer {
    margin: auto;
    transform: translateY(-50%);
}

.newsImage {
    background-image: url(../../../static/images/news.svg);
    background-repeat: no-repeat;
    width: 100px;
    height: 103px;
    margin: auto auto 16px;
}

.noSelectedText {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $neutralGray1;
}

.skeleton {
    display: flex;
    justify-content: center;
}
