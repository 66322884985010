@import 'app/variables';

.container {
    position: relative;
    min-height: 0;
    flex-grow: 1;
    background-image: url(../../static/images/welcomeBackground.svg);
    background-size: cover;
    background-position-x: 0;
    background-position-y: bottom;
    display: flex;
    flex-direction: column;

    @media ($mediaSmall) {
        background: none;
    }
}

.content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 16px;
    background: transparent;

    @media ($mediaSmallHeight) {
        top: 15%;
    }

    @media ($mediaSmall) {
        position: static;
        margin-top: 10px;
    }
}

.topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 88px;

    @media ($mediaSmall) {
        img {
            display: none;
        }
    }
}

.mainTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    @media ($mediaSmall) {
        margin-top: 10px;
    }
}

.logo {
    display: flex;
    flex: 0 0 auto;
    margin-top: 20vh;
    align-items: flex-end;
    justify-content: center;

    @media ($mediaSmall) {
        margin-top: 0;
    }
}

.icon {
    width: 36px;
    height: 54px;
}

.logoIcon {
    margin-right: 20px;

    @media ($mediaSmall) {
        width: 32px;
        height: 48px;
        margin-right: 8px;
    }
}

.logoText {
    width: 162px;
    height: 64px;

    @media ($mediaSmall) {
        width: 101px;
        height: 40px;
    }
}

.searchInput {
    width: 100%;
    max-width: 100%;

    @media ($mediaSmall) {
        margin: unset;
    }
}

.suggestionsContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 648px;
    margin: auto;
    margin-top: 40px;
    min-height: 0;

    @media ($mediaSmall) {
        width: 100%;
        margin-top: 24px;
    }
}
