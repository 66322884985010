.iframeViewer {
    width: 100%;
    height: 100%;
    background-color: transparent;
    overflow: hidden;

    iframe {
        border: none;
        width: 100%;
        height: 100%;
        background-color: #fff;
        margin: -2px;
    }
}
