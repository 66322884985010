@import 'app/variables';
@import 'app/mixins';

.statesContainer {
    display: flex;
    position: relative;
    padding: 26px 0;
    width: fit-content;
}

.stateItem {
    background: $neutralWhite;
    border-radius: 8px;
    padding: 16px;
    margin-right: 24px;
    min-width: 110px;
    position: relative;
    box-shadow: 0 2px 24px rgba(45, 45, 45, 0.02), 0 4px 44px rgba(45, 45, 45, 0.06);

    &::after {
        content: '';
        display: block;
        background-color: $neutralGray4;
        height: 1px;
        position: absolute;
        width: 24px;
        left: 100%;
        top: 50%;
    }

    &:last-child {
        margin-right: 0;

        &::after {
            display: none;
        }
    }
}

.icon {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-bottom: 4px;
    background-color: rgba($neutralGray2, 0.2);
    position: relative;

    &::before {
        content: '';
        display: block;
        background-color: $neutralWhite;
        width: 4px;
        height: 4px;
        position: absolute;
        top: 4px;
        left: 4px;
        border: 2px solid $neutralGray2;
        border-radius: 50%;
    }
}

.stateName {
    font-size: 14px;
    line-height: 20px;
    color: $neutralGray2;
    white-space: nowrap;
}

.done {
    .stateName {
        color: $neutralBlack;
    }

    .icon {
        background-color: rgba($SecondaryGreen, 0.2);

        &::before {
            background-color: $SecondaryGreen;
            width: 4px;
            height: 2px;
            position: absolute;
            top: 8px;
            left: 4px;
            border: none;
            border-radius: 1px;
            transform: rotate(45deg);
        }

        &::after {
            content: '';
            display: block;
            background-color: $SecondaryGreen;
            width: 7px;
            height: 2px;
            position: absolute;
            top: 7px;
            left: 5px;
            border-radius: 1px;
            transform: rotate(-45deg);
        }
    }
}

.current {
    .stateName {
        color: $primaryCoolBlue;
        font-weight: bold;
    }

    .icon {
        background-color: rgba($primaryCoolBlue, 0.2);

        &::before {
            border: 2px solid $primaryCoolBlue;
        }
    }
}

.leftButton,
.rightButton {
    span {
        background-color: $neutralWhite;
        width: 24px;
        height: 24px;
        display: block;
        position: relative;
        border: 1px solid $neutralGray4;
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        top: 50%;
        transform: translateY(-50%);

        &::before,
        &::after {
            content: '';
            display: block;
            width: 10px;
            height: 3px;
            background-color: $neutralGray3;
            border-radius: 1.5px;
            position: absolute;
        }
    }
}

.leftButton {
    background: linear-gradient(90deg, $neutralGray6 50%, rgba($neutralGray6, 0) 100%);

    span {
        margin: 0 11px 0 0;

        &::before {
            transform: rotate(45deg);
            left: 5px;
            top: 12px;
        }
        &::after {
            transform: rotate(-45deg);
            left: 5px;
            top: 7px;
        }
    }
}

.rightButton {
    background: linear-gradient(90deg, rgba($neutralGray6, 0) 0%, $neutralGray6 50%);

    span {
        margin: 0 0 0 11px;

        &::before {
            transform: rotate(45deg);
            left: 7px;
            top: 7px;
        }
        &::after {
            transform: rotate(-45deg);
            left: 7px;
            top: 12px;
        }
    }
}

@media ($mediaSmall) {
    .statesContainer {
        padding-bottom: 16px;
    }

    .leftButton {
        span {
            margin: 0 11px 0 0;
        }
    }

    .rightButton {
        span {
            margin: 0 0 0 11px;
        }
    }
}
