@import 'app/variables';

.table {
    display: flex;
    margin: 0 -12px;

    @media ($mediaSmall) {
        flex-wrap: wrap;
    }
    .tableColumn {
        margin: 0 12px;
        flex-grow: 1;
        flex-basis: 50%;
        width: 50%;
        display: table;

        .tableRow {
            display: table-row;
            font: 400 14px/20px 'ADNOC Sans', sans-serif;

            .tableCell {
                display: table-cell;
                width: 50%;
                word-break: break-word;
                padding-right: 8px;

                &:first-child {
                    color: $neutralGray2;
                }

                &:last-child {
                    padding-right: 0;
                }
            }

            &:not(:last-child) .tableCell {
                padding-bottom: 16px;
            }
        }

        @media ($mediaSmall) {
            width: 100%;
            flex-basis: 100%;

            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
    }
}
