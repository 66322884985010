@import 'app/variables';
@import 'app/mixins';

.chartWrapper {
    display: flex;

    & > div {
        width: 100%;
    }
}

.toggleWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;
}

.toggleButton {
    font-size: 14px;
    font-family: 'ADNOC Sans', sans-serif;
    background: none;
    height: 40px;
    padding: 0 16px;
    outline: none !important;
    cursor: pointer;
    border: 1px solid $neutralGray4;
    border-radius: 20px;
    margin: 0 8px 8px 0;

    &.hidden {
        color: $neutralGray4;
    }
}
