@import 'app/variables';

.container {
    display: flex;
    flex-direction: column;
    background: $neutralGray6;
    border-radius: 8px;
    padding-top: 8px;

    .item {
        display: flex;
        align-items: center;
        height: 40px;
        margin-bottom: 4px;

        .icon {
            margin: 0 8px 0 16px;
            img {
                width: 30px;
                height: 30px;
            }
        }

        .label {
            font: 700 8px/8px 'ADNOC Sans', sans-serif;
            color: $neutralWhite;
            background: $primaryRoyalBlue;
            border-radius: 13px;
            padding: 4px 4px 2px;
            margin-right: 12px;
        }

        .content {
            .title {
                font: normal 14px/20px 'ADNOC Sans', sans-serif;
                color: $neutralBlack;
                margin: 0;

                span {
                    font-weight: 500;
                }
            }

            .description {
                display: block;
                height: 0;
                transition: opacity 500ms ease-out;
                opacity: 0;
                font: normal 12px/16px 'ADNOC Sans', sans-serif;
                color: $neutralGray2;
                overflow-x: hidden;

                span {
                    font-weight: 500;
                }
            }
        }
        &:hover {
            background: $neutralGray4;

            .description {
                display: block;
                height: auto;
                opacity: 1;
            }
        }
    }

    .selected {
        background: $neutralGray4;
        &:hover {
            background: $neutralGray4;
        }

        .item .description {
            display: block;
            height: auto;
            opacity: 1;
        }
    }

    .showAll {
        font: 500 14px/20px 'ADNOC Sans', sans-serif;
        padding: 16px;
        color: $primaryRoyalBlue;
        text-align: center;
        cursor: pointer;
    }
}
