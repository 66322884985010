@import 'app/variables';

.title {
    display: flex;
    align-items: baseline;

    h3 {
        display: inline-block;
        flex: 0 0 auto;
        margin-right: 20px;
        margin-bottom: 0;
    }
}

.container {
    display: flex;
}

.label {
    font-size: 12px;
    line-height: 16px;
    color: $neutralGray2;
}

.value {
    font-size: 36px;
    line-height: 32px;
    margin-right: 50px;
    margin-top: 28px;
    margin-bottom: 12px;
}

.progress {
    width: 350px;
    height: 24px;
    background: $neutralGray4;
    position: relative;
    margin-top: 39px;
    margin-bottom: 9px;
}

.planned {
    position: absolute;
    width: 1px;
    height: 32px;
    background: $neutralGray2;
    bottom: 0;

    span {
        font-size: 14px;
        color: $neutralGray2;
        display: block;
        position: absolute;
        top: -20px;
        left: 0;
        transform: translate(-50%, 0);
    }

    .min {
        transform: translate(0, 0);
    }
}

.actual {
    position: absolute;
    background: $neutralGray2;
    top: 0;
    bottom: 0;
    left: 0;
}

.orange {
    background: $SecondaryOrange;
}

.brightRed {
    background: $SecondaryBrightRed;
}

.blue {
    background: $primaryCoolBlue;
}

.red {
    background-color: $SecondaryRed;
}

.small {
    display: block;

    .progress {
        width: 100%;
    }

    .max {
        transform: translate(-100%, 0);
    }
}

.hide {
    display: none;
}
