@import 'app/variables';

.title {
    margin-bottom: 4px;
    h4 {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        margin: 0 14px 0 0;
        padding: 0;
    }
}

.name {
    font-size: 14px;
    margin-bottom: 16px;
}

.details {
    margin-bottom: 7px;

    &:last-child {
        margin-bottom: 0;
    }
}

.label {
    font-size: 14px;
    line-height: 16px;
    color: $neutralGray2;
    margin-bottom: 6px;
}

.value {
    font-size: 14px;
    line-height: 20px;
}

.smallContainer {
    margin-bottom: 24px;
    border-bottom: 1px solid $neutralGray4;
    padding-bottom: 16px;

    &:last-child {
        border-bottom: none;
        margin-bottom: 0;
    }
}
