@import 'app/variables';

.description {
    margin-top: 20px;
    flex-grow: 1;
    background-color: $neutralWhite;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.tabsContainer {
    width: 100%;
    background: $neutralWhite;
    flex-grow: 1;
}

.tabsMuiAppBarRoot {
    padding: 0;
    margin: 0 16px;
    width: auto;
}

.container {
    display: flex;
    flex-direction: column;
    padding-bottom: 0 !important;
}

.imageContainer {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.documentsImage {
    background-image: url(../../../../static/images/documentsLoading.svg);
    width: 93px;
    height: 134px;
    margin-bottom: 16px;
}

.noDataContent {
    display: flex;
    justify-content: center;
}

@media ($mediaSmall) {
    .description {
        padding: 0;
        margin: 16px -16px 0;
    }

    .container {
        padding: 0;
    }
}
