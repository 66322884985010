@import 'app/variables';
@import 'app/mixins';

.container {
    position: relative;
    height: fit-content;
    display: flex;
}

.details {
    padding: 24px;
    border-radius: 8px;
    background: $neutralWhite;
    width: 50%;
    max-width: 416px;
    margin-right: 16px;
    flex: 0 0 auto;
    z-index: 100;
    box-shadow: 0 2px 24px rgba(45, 45, 45, 0.02), 0 4px 44px rgba(45, 45, 45, 0.06);
}

.map {
    position: relative;
    text-align: center;
    width: 100%;
    margin: 0;
    box-shadow: 0 2px 24px rgba(45, 45, 45, 0.02), 0 4px 44px rgba(45, 45, 45, 0.06);
    border-radius: 8px;
    overflow: hidden;
}

.map-container {
    position: relative;
    width: 100%;
}

.containerSmall {
    flex-wrap: wrap;
    margin-bottom: 16px;

    .details {
        position: relative;
        width: 100%;
        max-width: 100%;
        margin: 0 0 16px 0;
        border-radius: 8px 8px 0 0;
    }

    .map-container,
    .map {
        height: 400px;
    }
}

.placeDiv {
    z-index: 9999;
    position: absolute;
}

.g-map__error,
.g-map {
    position: relative;
    max-width: 100%;
    margin: 0;
    box-shadow: 0 2px 24px rgba(45, 45, 45, 0.02), 0 4px 44px rgba(45, 45, 45, 0.06);
    border-radius: 8px;
    overflow: hidden;
}

.g-map__error {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.placecard {
    &__container {
        box-shadow: rgba(0, 0, 0, 0.298039) 0 1px 4px -1px;
        max-width: 330px;
        width: 100%;
        background: rgb(255, 255, 255) none repeat scroll 0% 0% / auto padding-box border-box;
        border-radius: 2px 2px 2px 2px;
        font: normal normal normal normal 11px / normal Roboto, Arial, sans-serif;
        margin: 10px;
        padding: 9px 4px 9px 11px;
        overflow: hidden;
    }

    &__left {
        float: left;
        width: 75%;
    }

    &__right {
        text-align: center;
        float: left;
        width: 25%;
    }

    &__business-name {
        cursor: default;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 200px;
        perspective-origin: 100px 9.5px;
        transform-origin: 100px 9.5px;
        font: normal normal 500 normal 14px / normal Roboto, Arial, sans-serif;
        overflow: hidden;
        margin: 0;
    }

    &__info {
        color: rgb(91, 91, 91);
        cursor: default;
        width: 200px;
        column-rule-color: rgb(91, 91, 91);
        perspective-origin: 100px 16px;
        transform-origin: 100px 16px;
        border: 0 none rgb(91, 91, 91);
        font: normal normal normal normal 12px / normal Roboto, Arial, sans-serif;
        margin: 6px 0 0;
        outline: rgb(91, 91, 91) none 0;
    }

    &__direction-icon {
        background: rgba(0, 0, 0, 0)
            url('https://maps.gstatic.com/mapfiles/embed/images/entity11.png') repeat scroll 0 0 /
            70px 210px padding-box border-box;
        height: 22px;
        width: 22px;
        margin-right: auto;
        margin-left: auto;
    }

    &__direction-link {
        color: rgb(58, 132, 223);
        display: block;
        height: 43px;
        text-decoration: none;
        width: 54.7344px;
    }

    &__view-large {
        display: block;
        margin-top: 10px;
        color: rgb(58, 132, 223);
        text-decoration: none;
    }

    &__rating {
        display: flex;
        align-items: center;
        margin: 10px 0 5px;
    }

    &__ratingNumber {
        width: auto;
        margin: 0 6px 0 0;
        //    font-size: 16px;
    }
}
