$neutralGray1: #303030;
$neutralGray2: #8c8c8c;
$neutralGray3: #9d9fa0;
$neutralGray4: #eee;
$neutralGray5: #f6f7fa;
$neutralGray6: #f9f9f9;
$neutralWhite: #fff;
$neutralBlack: #000;
$neutralRed: #fdf7f7;
$darkGray: #9d9fa0;
$primarySkyGray: #d4d2cf;
$primarySteelGray: #4d5156;
$primarySteelGrayBg: #4d51561a;
$primaryCoolBlue: #0075c9;
$primaryRoyalBlue: #1859a9;
$secondaryActiveBlue: #51a3d6;
$secondaryPurple: #291446;
$SecondaryGreen: #109647;
$SecondaryRed: #a9343a;
$SecondaryBrightRed: #e32726;
$SecondaryBrown: #713034;
$BrightOrange: #faa22c;
$SecondaryOrange: #ee7623;
$highlight: #fdf051;
$tabRippleColor: #d5d5d5;

$mainBorderColor: $neutralGray4;
$mainBackgroundColor: $neutralGray6;

$grayColorGradient: #f5fafd;
$filtersCheckboxActive: #b3d7ff;

$tabsTopBarRootHeight: 40px;

$mediaExtraSmall: 'max-width: 480px';
$mediaSmall: 'max-width: 820px';
$mediaSmallHeight: 'max-height: 768px';
$mediaDesktop: 'min-width: 821px';
