@import 'app/variables';
@import 'app/mixins';

.legend {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
}

.color {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    flex: 0 0 auto;
    display: inline-block;
    margin-right: 5px;
}

.label {
    font-size: 12px;
    white-space: nowrap;
    margin: 0 10px 12px 5px;
}

.chart {
    position: relative;
}
