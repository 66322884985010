@import 'app/variables';
@import 'app/mixins';

.title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 16px;
}

.label {
    font-weight: bold;
}

.unit {
    text-align: right;
    padding: 0 10px;
}

.value {
    text-align: right;
}

.container {
    font-size: 12px;
    display: table;
    width: 100%;

    .row {
        display: table-row;
    }

    .label,
    .unit,
    .value {
        display: table-cell;
        padding-bottom: 12px;
    }
}

.total {
    border-top: 1px solid $neutralGray4;
    font-size: 12px;
    display: flex;
    padding-top: 12px;
    justify-content: space-between;
}
