@import 'app/variables';
@import 'app/mixins';

.title {
    @include n-lines-text-overflow(20px, 2);

    font: 500 14px/20px 'ADNOC Sans', sans-serif;
    margin: 4px 0;
}

.address {
    font: normal 14px/20px 'ADNOC Sans', sans-serif;
}

.labels {
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.statusLabel {
    display: flex;
    justify-content: center;
    align-content: center;
    height: 16px;
    width: 41px;
    border-radius: 4px;
    padding: 0.5px 4px;
    margin-right: 12px;
    font-size: 10px;
    font-weight: 500;
}

.active {
    color: $SecondaryGreen;
    background-color: rgba($SecondaryGreen, 0.1);
}

.closed {
    color: $SecondaryRed;
    background-color: rgba($SecondaryRed, 0.1);
}
