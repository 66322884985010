@import 'app/variables';
@import 'app/mixins';

.preview {
    overflow: hidden auto;
    flex-grow: 1;
}

.container {
    flex-grow: 1;
    padding: 24px;
    overflow: auto;
}

.skeleton {
    display: flex;
    justify-content: center;
}

.header {
    display: flex;
    margin-bottom: 16px;
    flex-wrap: wrap;

    .title {
        align-self: flex-start;
        margin: auto 16px auto 0;
        font-size: 18px;
        font-weight: 500;
        flex-grow: 100;

        @media ($mediaSmall) {
            margin-left: 0;
            margin-bottom: 16px;
            width: 100%;
        }
    }

    .date {
        display: flex;
        //justify-content: space-between;
        flex-grow: 1;
        flex-direction: row-reverse;

        @media ($mediaSmall) {
            justify-content: space-between;
            flex-direction: row;
        }

        .currentDateInfo {
            position: relative;
            vertical-align: middle;
            height: 32px;
            //margin-left: 16px;
            padding: 8px 16px 8px 12px;
            border-radius: 5px;
            font-size: 14px;
            line-height: 22px;
            //border: 1px solid $neutralGray4;
            //background: $neutralWhite;
            white-space: nowrap;
            //width: 160px;
            text-align: right;
            font-weight: 500;

            //&::before {
            //  content: '';
            //  display: block;
            //  position: absolute;
            //  right: 3.12%;
            //  background-image: url(../../../../static/images/calendar.svg);
            //  width: 18px;
            //  height: 18px;
            //  z-index: 1;
            //}
        }

        .periodToggler {
            align-self: center;
        }
    }
}

.itemContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 8px;
    background: $neutralWhite;
    box-shadow: 0 2px 24px rgba(45, 45, 45, 0.02), 0 4px 44px rgba(45, 45, 45, 0.06);
    min-width: 220px;

    @media ($mediaExtraSmall) {
        padding: 16px;
    }
}

.cardsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 8px -8px;

    @media ($mediaSmall) {
        flex-direction: column;
        margin: 0;
    }

    .cardContainer {
        flex: 1 1 30%;
        background: $neutralWhite;
        box-shadow: 0 2px 24px rgba(45, 45, 45, 0.02), 0 4px 44px rgba(45, 45, 45, 0.06);
        border-radius: 8px;
        padding: 16px;
        margin: 8px;
        min-width: 220px;
        height: 168px;

        @media ($mediaSmall) {
            margin: 0 0 16px;
        }
    }
}

.revenue {
    height: 357px;
    overflow-x: auto;
    overflow-y: hidden;

    @media ($mediaSmall) {
        height: 354px;
    }
}

.basketSize,
.customerInteraction {
    height: 328px;
    overflow-x: auto;
    overflow-y: hidden;

    @media ($mediaSmall) {
        height: 312px;
    }
}

.twoColumns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -8px;

    & > div {
        flex-grow: 1;
        margin-left: 8px;
        margin-right: 8px;
        width: calc(50% - 16px);
        min-width: 220px;
    }

    @media ($mediaSmall) {
        display: flex;
        flex-direction: column;
        margin: 0;

        & > div {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
        }
    }
}

@media ($mediaSmall) {
    .container {
        padding: 16px;
    }
}

.tableWrapperClass {
    .tableCell {
        text-align: left;
    }
}
