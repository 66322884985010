@import 'app/variables';
@import 'app/mixins';

.peakHoursWrapper {
    overflow-x: scroll;

    @media ($mediaDesktop) {
        &::-webkit-scrollbar {
            min-height: 6px;
        }
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .title {
        font: 500 16px/24px 'ADNOC Sans', sans-serif;
        margin-top: 0;
    }
}

.table {
    table-layout: fixed;
    width: 100%;
    min-width: 500px;
    border-spacing: 1px;

    th,
    td {
        height: 24px;
        font: normal 10px/16px 'Segoe UI', sans-serif;
        color: #808080;
        width: 36px;
    }

    th {
        text-align: left;
    }

    td {
        background-color: $SecondaryOrange;
        text-align: center;
    }

    .days {
        background: none;
        opacity: 1;
    }

    .colorType1 {
        opacity: 0.1;
    }

    .colorType2 {
        opacity: 0.2;
    }

    .colorType3 {
        opacity: 0.4;
    }

    .colorType4 {
        opacity: 0.6;
    }

    .colorType5 {
        opacity: 0.8;
    }

    .colorType6 {
        opacity: 1;
    }
}
