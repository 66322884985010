@import 'app/variables';

.wrapper {
    position: relative;
    min-height: 464px;
    overflow: hidden;

    @media ($mediaSmall) {
        min-height: 373px;
    }
}

.mapWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.map {
    position: absolute;
    top: 0;
    right: 0;
    fill: #ccc;
    background: $neutralWhite;
    stroke: $neutralWhite;

    .selectedCountry {
        fill: #f2a93b;
    }
}

.content {
    position: relative;
    z-index: 2;
}
