@import 'app/variables';
@import 'app/mixins';

.container {
    width: 100%;
    position: relative;
}

.contentContainer {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        display: none;
    }
}

.content {
    display: block;
}

.left,
.right {
    position: absolute;
    top: 0;
    bottom: 0;
    cursor: pointer;
    min-width: 10px;
    background: $neutralGray2;
}

.left {
    left: 0;
}

.right {
    right: 0;
}
