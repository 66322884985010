@import 'app/variables';
@import 'app/mixins';

.noResults {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    pointer-events: none;
}

.promptText {
    margin-top: 15px;
    color: $neutralBlack;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    padding: 0 35px;
    text-align: center;
}

.helperText {
    margin-top: 10px;
    color: $neutralGray2;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
}

.image {
    background-repeat: no-repeat;
    background-position: center;
    width: 120px;
    height: 110px;
}

@media ($mediaSmall) {
    .promptText {
        font-size: 16px;
    }
}
