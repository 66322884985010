@import 'app/variables';

.filterButton {
    border: 0 none;
    background: transparent;
    cursor: pointer;
    line-height: 20px;
    font-weight: 500;
    font-size: 14px;
    padding: 0;
    position: relative;
    display: flex;
    height: 24px;
    align-items: center;
    outline: none;
    color: $neutralBlack;
}

.filterButton::before {
    content: '';
    width: 16px;
    height: 16px;
    margin-right: 4px;
    background: url(../../../static/images/filter_icon.svg) no-repeat;
    background-size: contain;
}
