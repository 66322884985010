@import 'app/variables';
@import 'app/mixins';

.toggleGroup {
    width: 144px;
    height: 32px;
    border-radius: 40px;
    background-color: $neutralWhite;
    border: 1px solid $neutralGray4;
    box-sizing: border-box;
}

.toggleBtn {
    flex: 1 0 0;
    width: 68px;
    height: 32px;
    margin-top: -1px;
    border-radius: 40px !important;
    border: none;
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
    color: $neutralGray1;

    &:first-of-type {
        margin-left: -1px;
    }

    &:last-of-type {
        margin-right: -1px;
    }
}

.selected {
    color: $neutralWhite !important;
    background-color: $primaryCoolBlue !important;
}
