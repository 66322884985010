@import 'app/variables';

.layoutWrapper {
    width: 100%;
    flex-grow: 1;
    overflow: auto;
}

.layout {
    max-width: 1016px;
    box-sizing: border-box;
    width: 100%;
    padding: 24px;
    margin: 0 auto;
    min-height: 100%;

    @media ($mediaSmall) {
        padding: 16px;
    }
}
