@import 'app/variables';

.link {
    position: relative;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $primaryRoyalBlue;
    font-family: 'ADNOC Sans', sans-serif;

    &:hover {
        color: $primaryCoolBlue;

        svg path {
            stroke: $primaryCoolBlue;
        }
    }
}
