@import 'app/variables';
@import 'app/mixins';

.itemHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0;
    margin-bottom: 6px;
}

.text {
    display: inline-block;
    padding-top: 2px;
    font: normal 12px/16px 'ADNOC Sans', sans-serif;
    color: $neutralGray2;
}

.title {
    @include n-lines-text-overflow(20px, 2);

    font: 500 14px/20px 'ADNOC Sans', sans-serif;
    color: $neutralBlack;
    margin-bottom: 4px;
}

.titleLong {
    @include n-lines-text-overflow(20px, 3);
}
