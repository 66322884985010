@import 'app/variables';
@import 'app/mixins';

.peopleListItem {
    display: flex;
    font-size: 14px;
    line-height: 20px;
}
.info {
    display: flex;
    flex-direction: column;
    min-width: 0;
}

.avatar {
    margin-right: 16px;
    width: 40px;
    height: 40px;
    font-size: 18px;
}
.name {
    @include text-ellipsis;

    font-weight: 500;
    margin-bottom: 5px;
}
.jobTitle {
    @include text-ellipsis;

    margin-bottom: 4px;
}
.unit {
    @include text-ellipsis;

    color: $neutralGray2;
}

.location {
    color: $neutralGray2;
}
