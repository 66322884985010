@import 'app/variables';
@import 'app/mixins';

.label {
    display: inline-block;
    box-sizing: border-box;
    font-size: 10px;
    font-weight: 500;
    height: 16px;
    line-height: 18px;
    padding: 1px 4px 0;
    border-radius: 4px;
    margin-right: 4px;
    white-space: nowrap;
    color: $neutralWhite;
    background: $secondaryActiveBlue;

    &.blue {
        color: $primaryCoolBlue;
        background: rgba($primaryCoolBlue, 0.05);
    }
    &.darkBlue {
        color: #1859a9;
        background: rgba(#1859a9, 0.05);
    }
    &.whiteBlue {
        color: $neutralWhite;
        background: $secondaryActiveBlue;
    }
    &.orange {
        color: $SecondaryOrange;
        background: rgba($SecondaryOrange, 0.05);
    }
    &.brightOrange {
        color: $BrightOrange;
        background: rgba($BrightOrange, 0.05);
    }
    &.whiteBrightOrange {
        color: $neutralWhite;
        background: $BrightOrange;
    }
    &.brightRed {
        color: $SecondaryBrightRed;
        background: rgba($SecondaryBrightRed, 0.05);
    }
    &.red {
        color: $SecondaryRed;
        background: rgba($SecondaryRed, 0.05);
    }
    &.brown {
        color: $SecondaryBrown;
        background: rgba($SecondaryBrown, 0.01);
    }
    &.green {
        color: $SecondaryGreen;
        background: rgba($SecondaryGreen, 0.1);
    }
    &.whiteGreen {
        color: $neutralWhite;
        background: $SecondaryGreen;
    }
    &.gray {
        color: $neutralGray1;
        background: rgba($neutralGray1, 0.1);
    }
    &.lightGray {
        color: $neutralGray3;
        background: rgba($neutralGray3, 0.1);
    }
}
