@import 'app/variables';

.chart {
    margin: 20px 0 16px;
}

.legend {
    display: flex;
    flex-wrap: wrap;

    & > div {
        display: flex;
        flex-wrap: wrap;
    }
}

.planed,
.actual,
.revised,
.cumulativePlaned,
.cumulativeActual,
.cumulativeRevised {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    margin-right: 16px;
    color: $neutralGray1;

    &::before {
        margin: 0 8px;
        content: '';
        display: block;
    }
}

.planed,
.actual,
.revised {
    &::before {
        width: 8px;
        height: 8px;
        border-radius: 50%;
    }
}

.cumulativePlaned,
.cumulativeActual,
.cumulativeRevised {
    &::before {
        width: 14px;
        height: 3px;
    }
}

.planed::before {
    background-color: #089bd7;
}

.actual::before {
    background-color: #109647;
}

.revised::before {
    background-color: #faa22c;
}

.cumulativePlaned::before {
    background-color: #0075c9;
}

.cumulativeActual::before {
    background-color: #00754a;
}

.cumulativeRevised::before {
    background-color: #f19923;
}

.small {
    .legend {
        display: flex;
        flex-wrap: wrap;

        & > div {
            display: block;
        }
    }
}
