@import 'app/variables';

.header {
    display: flex;
    justify-content: space-between;
    padding: 0 24px 13px 24px;
    @media ($mediaSmall) {
        padding: 0 12px 8px 12px;
    }
}

.title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    @media ($mediaSmall) {
        font-size: 16px;
    }
}
