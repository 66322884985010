@import 'app/variables';
@import 'app/mixins';

.container {
    display: flex;
    border-bottom: 1px solid $neutralGray4;
    height: 41px;
    margin-top: -7px;
}

.button {
    border: none;
    border-bottom: 1px solid transparent;
    font-size: 14px;
    font-family: 'ADNOC Sans', sans-serif;
    background: none;
    height: 40px;
    padding: 0 16px;
    outline: none !important;
    cursor: pointer;
}

.active {
    border-bottom: 1px solid $primaryCoolBlue;
}
