.skeleton {
    padding: 0 16px;
}

.items {
    margin-top: 16px;
}

.skeletonItem {
    display: flex;
    margin-bottom: 8px;

    &:last-child {
        margin-bottom: 0;
    }
}

.avatar {
    margin-right: 8px;
}

.subTitle {
    margin-top: 4px;
}
