@import 'app/variables';
@import 'app/mixins';

.legend {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 10px;
    line-height: 16px;
    margin-top: 16px;

    .label {
        white-space: nowrap;
        margin: 0 10px 0 8px;
    }
}

.color {
    display: inline-block;
    position: relative;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    margin-right: 5px;

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: -4px;
        top: 4px;
        width: 17px;
        height: 0;
        border-top-width: 3px;
        border-top-style: solid;
    }
}
