@import '../../../../variables';
@import '../../../../mixins';

.errorContainer {
    position: absolute;
    z-index: 9999;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: radial-gradient((circle at 50% -250%, #fff 92%, #fff, #f5fafd 92%, #fff 94%));

    @media ($mediaSmall) {
        background: radial-gradient(
            circle at 50% -40%,
            #fff 80%,
            #fff,
            $grayColorGradient 80%,
            #fff 92%
        );
    }

    @media ($mediaExtraSmall) {
        background: radial-gradient(
            circle at 50% 10%,
            #fff 73%,
            #fff,
            $grayColorGradient 50%,
            #fff 92%
        );
    }
}

.error {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    transform: translateY(-20%);
}

.title {
    font-weight: 500;
    font-size: 18px;
    color: $neutralBlack;
    text-align: center;

    @media ($mediaSmall) {
        font-size: 16px;
    }
}

.description {
    font-size: 14px;
    color: $neutralGray2;
    text-align: center;
    margin: 12px 12px 0;
    line-height: 20px;
    font-family: inherit;
}

.image {
    background-repeat: no-repeat;
    width: 120px;
    height: 110px;

    @media ($mediaExtraSmall) {
        width: 74px;
        height: 72px;
        background-size: 74px;
        margin-bottom: 15px;
    }
}

.button {
    margin-top: 32px;
}
