@import 'app/variables';

.commitments {
    .commitmentsHeader {
        display: flex;
        align-items: center;
        margin-bottom: 13px;

        .commitmentsTitle {
            font: 500 16px/24px 'ADNOC Sans', sans-serif;
            margin-right: 4px;
        }
        .badge {
            font: 400 10px/13px 'ADNOC Sans', sans-serif;
            margin-left: 4px;
            padding: 2px 4px 0;
            display: block;
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.1);
            color: #4d5156;
        }
    }

    .itemsContainer {
        display: flex;

        @media (max-width: 1280px) {
            flex-wrap: wrap;
            margin-bottom: -12px;
        }

        .item {
            flex-grow: 1;
            width: 25%;
            border-left: 1px solid $neutralGray4;
            padding-left: 24px;

            &:first-child {
                border-left: none;
                padding-left: 0;
            }

            .itemTitle {
                font: 400 14px/20px 'ADNOC Sans', sans-serif;
                margin-bottom: 8px;
                white-space: nowrap;
            }
            .itemValue {
                font: 400 20px/24px 'ADNOC Sans', sans-serif;
                white-space: nowrap;
            }

            @media (max-width: 1280px) {
                flex-basis: 50%;
                width: 50%;
                margin-bottom: 12px;
                padding-left: 12px;

                &:nth-child(odd) {
                    border-left: 0;
                    padding-left: 0;
                }
            }
        }
    }
}
