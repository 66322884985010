.containerNoData {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.chartContainer {
    position: relative;
    width: 100%;
}

@media (max-width: 290px) {
    .chartContainer {
        width: 290px;
    }
}

.chart {
    display: block;
    position: relative;
}
