@import 'app/variables';
@import 'app/mixins';

.suggestionItem {
    min-height: 54px;
    padding: 8px 16px;
    display: flex;

    &:hover {
        background-color: $neutralGray6;
        cursor: pointer;
    }
}

.title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.subTitle {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $neutralGray2;
    margin-top: 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &.darkSubTitle {
        color: $neutralBlack;
    }
}

.icon {
    width: 32px;
    flex-shrink: 0;
    margin-right: 8px;

    img {
        width: 100%;
    }
}

.icon.cover {
    height: 32px;
    position: relative;

    img {
        position: absolute;
        height: 100%;
        object-fit: cover;
    }
}

.mailIcon,
.employeeIcon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    font-size: 14px;
}

.textAvatar {
    width: 36px;
    height: 36px;
    margin-right: 8px;
    font-size: 16px;
}

.info {
    width: 100%;
    overflow: hidden;
}

.status {
    display: inline-block;
    color: $neutralWhite;
    background: $secondaryActiveBlue;
    margin-right: 8px;
    font-size: 10px;
    line-height: 14px;
    font-weight: 500;
    border-radius: 4px;
    padding: 2px 6px 0 6px;
    position: relative;
    top: -1px;
}

.reference {
    font-size: 12px;
    color: $neutralGray2;
}

.justifyContentBetween {
    display: flex;
    justify-content: space-between;
}

.active {
    color: $SecondaryGreen;
    background-color: rgba($SecondaryGreen, 0.1);
}

.closed {
    color: $SecondaryRed;
    background-color: rgba($SecondaryRed, 0.1);
}

.selected {
    background: $neutralGray4;

    &:hover {
        background: $neutralGray4;
    }
}

.hasAttachments {
    @include icon;

    background-image: url(~app/static/images/paperClip.svg);
}

.subInfo {
    font-size: 12px;
    color: $neutralGray2;
    text-align: right;
    white-space: nowrap;
    margin-left: 16px;
}

.date {
    line-height: 16px;
    margin-bottom: 8px;
}
