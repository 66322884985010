@import 'app/variables';

.infoCard {
    max-width: 230px;
    width: 100%;
    padding: 16px;
    position: relative;
    display: flex;
    align-items: stretch;
    background: $neutralWhite;
    box-shadow: 0 2px 24px rgba(45, 45, 45, 0.02), 0 4px 44px rgba(45, 45, 45, 0.06);
    border-radius: 8px;
    margin: 0 8px 16px 8px;

    @media (min-width: 684px) and ($mediaSmall) {
        max-width: 316px;
    }
    @media (max-width: 512px) {
        max-width: 200px;
    }
    @media (max-width: 452px) {
        max-width: none;
    }
}

.infoCardWrapper {
    position: relative;
    flex-grow: 1;
    font: 400 12px/16px 'ADNOC Sans', sans-serif;
    color: $neutralBlack;
}
