@import '../../../../../variables';
@import '../../../../../mixins';

.container {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    pointer-events: none;
}

.title {
    margin-top: 15px;
    color: $neutralBlack;
    font: 500 18px/40px 'ADNOC Sans', sans-serif;
    padding: 0 35px;
    text-align: center;
}

.description {
    margin-top: 8px;
    color: $neutralGray2;
    font: normal 14px/20px 'ADNOC Sans', sans-serif;
    text-align: center;
}

.image {
    background: url(/app/static/images/requestTimeout.svg) center no-repeat;
    width: 114px;
    height: 96px;
}

@media ($mediaExtraSmall) {
    .image {
        width: 74px;
        height: 72px;
    }
}
