@import 'app/variables';

.filterPanel {
    width: 232px;
    border-right: 1px solid $mainBorderColor;
    display: flex;
    flex: 0 0 auto;

    @media ($mediaSmall) {
        width: 100%;
    }
}
