@import 'app/variables';
@import 'app/mixins';

.documentListItem {
    display: flex;
    text-decoration: none;
    color: inherit;
    word-break: break-word;
    overflow: hidden;

    &:hover {
        color: inherit;
    }
}

.thumbnail {
    flex: 0 0 auto;
    margin-right: 16px;
    width: 40px;
    height: 40px;
}

.title {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 4px;
    line-height: 20px;
}

.info {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 4px;
    flex-grow: 0;
    overflow: hidden;
}

.author {
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    max-width: 65px;
    white-space: nowrap;
    line-height: 12px;
    height: 12px;
    position: relative;
    top: 3px;
}

.path {
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
}

.pathArray {
    padding-top: 0;
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 16px;
    max-height: 16px;

    span:last-child {
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.dateCreated,
.dateModified {
    font-size: 12px;
    line-height: 16px;
    color: $neutralGray2;
    margin-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.description {
    max-width: 100%;
}
