@import 'app/variables';

.header {
    display: flex;
    position: relative;
    height: 100%;

    @media ($mediaSmall) {
        flex-direction: column;
    }
}

.mainTitleMobile,
.mainTitleDesktop {
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    display: flex;
    align-items: center;
}

.mainTitleDesktop {
    @media ($mediaSmall) {
        display: none;
    }
}

.mainTitleMobile {
    @media ($mediaDesktop) {
        display: none;
    }
    @media ($mediaSmall) {
        max-width: 450px;
        width: 100%;
        margin: 36px auto 16px;
        font-size: 20px;
    }
}

.leftSide {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
}

.backButton {
    display: flex;
    align-items: center;
    margin: 36px 0 28px;
    height: 24px;
}

.button {
    position: relative;
    top: -2px;
}

.logo {
    display: flex;
    align-items: center;
}

.title {
    margin-left: 8px;
    font-size: 20px;

    @media ($mediaDesktop) {
        display: none;
    }
}

.rightSide {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    @media ($mediaSmall) {
        display: none;
    }
}
