@import 'app/variables';

.container {
    position: relative;
    font: normal 14px/34px 'ADNOC Sans', sans-serif;
    display: inline-flex;
    align-items: center;
    background: $neutralWhite;
    border: 1px solid $neutralGray4;
    padding: 5px 5px 5px 11px;
    border-radius: 5px;
}

.selectMenu {
    font-size: 14px;
    font-weight: 500;
    font-family: 'ADNOC Sans', sans-serif;

    & :focus {
        background-color: $neutralWhite;
    }
}

.selectRoot {
    padding-bottom: 0;
    padding-top: 3px;
}

.menuItem {
    font-size: 14px;
    font-family: 'ADNOC Sans', sans-serif;
    padding-top: 10px;

    &:focus {
        background-color: $neutralWhite;
    }
}

.icon {
    fill: $neutralGray3;
    width: 19px;
    height: 19px;
    right: 2px;
    top: 0;
}
