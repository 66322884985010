@import 'app/variables';
@import 'app/mixins';

.mailListItem {
    display: flex;
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    align-items: flex-start;
    text-decoration: none;
    color: #000;
    cursor: pointer;
}

.avatar {
    width: 40px;
    height: 40px;
    font-size: 18px;
    margin-right: 16px;
}

.info {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
}

.sendInfo {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.senderName {
    @include text-ellipsis;

    font-weight: 500;
    text-align: left;
    font-size: 14px;
    line-height: 26px;
}

.dateSent {
    color: $neutralGray2;
    font-size: 12px;
    line-height: 20px;
    word-break: keep-all;
    white-space: nowrap;
}

.mainInfo {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    line-height: 22px;
    margin-bottom: 3px;
}

.subject {
    @include text-ellipsis;

    text-align: left;
    font-size: 14px;
    flex-grow: 1;
}

.hasAttachments {
    background-image: url(../../../../static/images/paperClip.svg);
    width: 12px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    flex-grow: 0;
    flex-shrink: 0;
}

.description {
    @include n-lines-text-overflow(20px, 2);

    color: $neutralGray2;
    text-align: left;
    font-size: 14px;
}

.highlight {
    background: $highlight;
}
