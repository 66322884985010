.Gmarker__label {
    max-width: 150px;
    width: 150px;
    word-wrap: break-word;
    position: absolute;
    top: 36px;
    left: 16px;
    text-shadow: 0 0 14px rgb(242, 243, 244);
    white-space: pre-wrap;
    font-weight: 500;
    text-align: left;
    height: 40px;
    display: flex;
    align-items: center;
}
