.item {
    display: flex;
    flex-direction: row;
}

.info {
    margin-left: 16px;

    & > * {
        margin-bottom: 8px;
    }
}
