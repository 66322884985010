@import 'app/variables';

.root,
.filter,
.list,
.preview {
    height: 100%;
    overflow: hidden;
    align-items: stretch;
    display: flex;
    flex: 0 0 auto;
    position: relative;
}

.root {
    width: 100%;
}

.filter {
    transition: width 300ms;
    width: 232px;
}

.list {
    width: 336px;

    &.hidden {
        display: none;
    }
}

.hideFilter {
    .filter {
        width: 0;
    }
    .list {
        width: 357px;
    }
}

.preview {
    background: $mainBackgroundColor;
    min-width: 0;
    flex: 1 1 auto;
    position: relative;
}

.previewPopupBack {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 99999;
}

.previewPopup {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: $neutralWhite;
    border-top: 8px solid $primaryCoolBlue;
    padding: 20px 24px 24px;
    max-width: 443px;

    .close {
        position: absolute;
        top: 24px;
        right: 22px;
        width: 18px;
        height: 18px;
        background: none;
        border: none;
        outline: none;
        border-radius: 5px;
        cursor: pointer;

        &::before,
        &::after {
            content: '';
            display: block;
            width: 21px;
            height: 1px;
            background: $neutralGray3;
            position: absolute;
            left: 50%;
            top: 50%;
        }

        &::before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &::after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    h3 {
        margin: 0;
        font-family: 'ADNOC Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
    }

    p {
        padding: 0;
        font-family: 'ADNOC Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
    }

    .button {
        padding: 6px 16px;
        color: $neutralWhite;
        background-color: $primaryRoyalBlue;
        border: none;
        outline: none;
        border-radius: 5px;
        float: right;
        cursor: pointer;
    }
}

@media ($mediaSmall) {
    .root,
    .filter,
    .list,
    .preview {
        flex: 1 1 auto;
        width: 100%;
    }
}
