@import 'app/variables';
@import 'app/mixins';

.container {
    flex-grow: 1;
    overflow: auto;
    padding-top: 0;
}

.content {
    padding: 0;

    @media ($mediaSmall) {
        padding: 16px 0;
    }

    &:first-child {
        padding-top: 26px;
    }
}

.itemContainer {
    background: $neutralWhite;
    box-shadow: 0 2px 24px rgba(45, 45, 45, 0.02), 0 4px 44px rgba(45, 45, 45, 0.06);
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;

    & h3 {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 16px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 16px;

        h3 {
            margin: 0;
        }

        a {
            text-decoration: none;
            color: $primaryRoyalBlue;
            font-size: 14px;
        }

        img {
            margin: 0 0 -1px 8px;
        }
    }

    & > p {
        font-size: 14px;
        line-height: 24px;
        padding: 0;
        margin: 0;
        white-space: pre-line;
        word-break: break-word;
    }

    hr {
        background-color: $neutralGray4;
        height: 1px;
        width: 100%;
        display: block;
        border: none;
        margin: 8px 0 24px;
    }

    &:first-child {
        padding-bottom: 8px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    @media ($mediaExtraSmall) {
        padding: 16px;
    }
}

.image {
    border-radius: 8px;
    max-height: 144px;
    max-width: 100%;
}

.link {
    text-decoration: none;
    color: $primaryCoolBlue;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
}

.userInfo {
    display: flex;

    button {
        padding-left: 5px;
    }
}

.twoColumnContainer {
    display: flex;

    & > div {
        flex: 0 0 auto;
        width: 50%;

        &:first-child {
            padding-right: 24px;
        }
    }
}

.catalogItemContainer {
    display: flex;

    .catalogImage {
        flex: 0 0 auto;
        height: 208px;
        width: 340px;
        margin-right: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        overflow: hidden;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .catalogDescription {
        width: calc(100% - 364px);
    }
}

.small {
    .twoColumnContainer {
        flex-direction: column;

        & > div {
            flex: 0 0 auto;
            width: 100%;

            &:first-child {
                padding-right: 0;
            }
        }
    }

    .catalogItemContainer {
        flex-direction: column;

        .catalogImage {
            width: 100%;
            max-width: 100%;
            margin-right: 0;
            margin-bottom: 24px;
        }

        .catalogDescription {
            width: 100%;
        }
    }
}
