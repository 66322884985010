@import-normalize;
@import '~react-redux-toastr/lib/css/react-redux-toastr.min.css';
@import '~@one-hub/onehub-web-lib/dist/index.css';
@import 'app/mixins';
@import 'app/variables';
@import 'app/toastr';

@font-face {
    font-family: 'ADNOC Sans';
    src: url('app/static/fonts/ADNOCSans_Rg.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ADNOC Sans';
    src: url('app/static/fonts/ADNOCSans_Md.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

html,
html *,
body,
body * {
    box-sizing: border-box;
    @include scroll-bar;
}

body {
    font-family: 'ADNOC Sans', sans-serif;
}

.highlight {
    background: $highlight;
}

// https://github.com/mui/material-ui/issues/31849
.MuiMenu-paper {
    transition-property: opacity !important;
}

@media ($mediaSmall) {
    .mobile-hidden {
        display: none !important;
    }
}
@media ($mediaDesktop) {
    .desktop-hidden {
        display: none !important;
    }
}
