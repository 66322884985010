@import 'app/mixins';
@import 'app/variables';

.container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    &.withoutFilter {
        padding-left: 24px;

        @media ($mediaSmall) {
            padding-left: 0;
        }
    }

    &.withoutExtraPanel.withoutFilter {
        padding-top: 24px;

        @media ($mediaSmall) {
            padding-top: 0;
        }
    }
}

.loadMore {
    text-align: center;
    padding: 12px 0 6px;
    width: 100%;
    background-color: $neutralGray5;
    font-family: 'ADNOC Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: $primaryRoyalBlue;
    line-height: 20px;

    &:hover {
        color: $primaryCoolBlue;
    }
}

.list {
    flex-grow: 1;

    &.listLoading {
        overflow: hidden;
    }
}

.loadMoreContainer {
    outline: none;
    padding: 0 24px;
    cursor: pointer;
    display: flex;
    bottom: 0;
    left: 0;
    width: 100%;
    position: absolute;
    background: white;

    @media ($mediaSmall) {
        display: none;
    }
}

.filterPanel {
    width: 232px;
    border-right: 1px solid $mainBorderColor;
    display: flex;
    flex: 0 0 auto;

    @media ($mediaSmall) {
        width: 100%;
    }
}

.listPanel {
    display: flex;
    flex: 1 0 0;
    min-width: 0;
    padding-top: 25px;
    flex-direction: column;
    position: relative;

    @media ($mediaSmall) {
        padding-top: 16px;
    }
}

.extrasPanel {
    padding: 0 20px;
    margin-bottom: 18px;
    margin-top: 24px;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-template-areas:
        'filter none'
        'sorting totalCount';

    @media ($mediaSmall) {
        flex-direction: row;
        padding: 0 16px;
        margin-top: 8px;
        margin-bottom: 8px;
        grid-template-areas:
            'filter sorting'
            'totalCount none';
    }
}

.filterButton {
    grid-area: filter;
    margin-bottom: 8px;
}

.showFiltersButton {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    cursor: pointer;
    color: $primaryRoyalBlue;
    margin-bottom: 6px;
    grid-area: filter;

    @media ($mediaSmall) {
        display: none;
    }
}

.sortPanel.sortPanel {
    white-space: nowrap;
    margin-top: -2px;
    grid-area: sorting;
    display: flex;
    justify-content: flex-start;

    @media ($mediaSmall) {
        justify-content: flex-end;
        align-self: flex-start;
    }
}

.totalItemsCount {
    text-align: right;
    font-size: 12px;
    line-height: 16px;
    color: $neutralGray2;
    grid-area: totalCount;

    @media ($mediaSmall) {
        text-align: left;
    }
}
