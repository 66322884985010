@import 'app/variables';
@import 'app/mixins';

.info {
    flex-shrink: 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    z-index: 1;
}
.subject {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding: 0 0 8px;

    @media ($mediaSmall) {
        padding: 16px 16px 8px;
    }
}
.subjectText {
    @include n-lines-text-overflow(24px, 2);
}
.mainInfo {
    padding: 16px 16px 8px 16px;
    display: flex;
    background: $neutralWhite;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    @media ($mediaSmall) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}
.allInfo {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-width: 0;
}
.mailInfo {
    flex-grow: 1;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 0 3px -5px;
    & > * {
        margin: 0 0 5px 5px;
    }
}
.mailInfoAvatar {
    width: 40px;
    height: 40px;
    font-size: 18px;
    margin-right: 16px;
}
.senderName {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}
.dateSent {
    font-size: 12px;
    line-height: 16px;
    color: $neutralGray2;
    flex-shrink: 0;
}
.toSection {
    font-size: 12px;
    line-height: 16px;
    display: flex;
    flex: 1 1 auto;
    min-width: 0;
}
.toHeading {
    font-size: 12px;
    font-weight: 500;
    margin-right: 4px;
}
.to {
    font-size: 12px;
    line-height: 16px;
}
.ccSection {
    font-size: 12px;
    line-height: 16px;
    display: flex;
    flex: 1 1 auto;
    min-width: 0;
    transition: 1s 0.5s;
    overflow: hidden;
}
.ccHeading {
    font-size: 12px;
    font-weight: 500;
    margin-right: 4px;
}
.cc {
    font-size: 12px;
    line-height: 16px;
}
.collapsedInfo {
    padding-top: 1px;
    background-color: $neutralWhite;
}

.collapsedSubject {
    flex-shrink: 0;
}

.linkWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;

    & > * {
        margin: 0 0 5px 5px;
    }

    margin: 0 0 -1px -5px;
}

.more,
.less {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 5px;
    vertical-align: middle;
    cursor: pointer;
    background: url(../../../../static/images/collapseArrow.svg) no-repeat;
}

.less {
    transform: rotate(180deg);
}
