@import 'app/variables';

.title {
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 15px;
    padding-left: 24px;
    position: relative;
    user-select: none;

    &::before {
        content: '';
        display: block;
        border: 2px solid $neutralGray3;
        border-top: none;
        border-left: none;
        width: 7px;
        height: 7px;
        position: absolute;
        left: 2px;
        top: 2px;
        transform: rotate(45deg);
        transition: transform linear 0.1s;
    }

    &.collapse::before {
        transform: rotate(-45deg);
    }
}

.milestone {
    border-bottom: 1px solid $neutralGray4;
    margin-bottom: 15px;
}

.milestoneTitle {
    display: flex;
}

.detailsContainer {
    display: flex;
    margin: 8px 0 17px 24px;
}

.details {
    margin-right: 40px;
    border-right: 1px solid $neutralGray4;

    &:last-child {
        border-right: none;
    }
}

.label {
    font-size: 14px;
    line-height: 16px;
    color: $neutralGray2;
    margin-bottom: 8px;
    width: 120px;
}

.value {
    font-size: 14px;
    line-height: 20px;
}

.button {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border: none;
    background: none;
    color: $primaryRoyalBlue;
    cursor: pointer;
    margin: 0;
    padding: 0 16px;
    font-family: 'ADNOC Sans', sans-serif;
}
