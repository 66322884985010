@import 'app/variables';
@import 'app/mixins';

.container {
    display: flex;
    border: 1px solid $primarySkyGray;
    border-radius: 39px;
    height: 32px;
    margin-top: -7px;
    width: fit-content;
}

.button {
    border: none;
    border-bottom: 1px solid transparent;
    font-size: 12px;
    font-family: 'ADNOC Sans', sans-serif;
    background: none;
    height: 32px;
    padding: 0 20px;
    outline: none !important;
    cursor: pointer;
    border-radius: 39px;
    margin: -1px;
}

.active {
    background: $primaryCoolBlue;
    color: $neutralWhite;
}
