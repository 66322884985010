@import 'app/variables';

.label {
    font-size: 12px;
    line-height: 16px;
    color: $neutralGray2;
}

.value {
    font-size: 36px;
    line-height: 32px;
    margin-bottom: 40px;
}

.progressBar {
    width: 100%;
    height: 24px;
    background: $neutralGray4;
    position: relative;
    margin-bottom: 8px;
}

.progress {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 5%;
}

.planned {
    position: absolute;
    width: 1px;
    height: 32px;
    background: $neutralGray2;
    bottom: 0;

    span {
        font-size: 14px;
        color: $neutralGray2;
        display: block;
        position: absolute;
        top: -20px;
        left: 0;
        transform: translate(-50%, 0);
    }

    .min {
        transform: translate(0, 0);
    }

    .max {
        transform: translate(-100%, 0);
    }
}

.orange {
    background: $SecondaryOrange;
}

.green {
    background: $SecondaryGreen;
}

.space {
    height: 32px;
}

.container {
    padding: 16px;
}
