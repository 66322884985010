@import 'app/variables';
@import 'app/mixins';

.details {
    display: flex;
    flex-direction: column;
}

.detailsItem {
    display: flex;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 16px;

    .name {
        width: 140px;
        color: $neutralGray2;
        flex: 0 0 auto;
        margin-right: 10px;
        white-space: pre-line;
    }

    .value {
        flex: 1 1 auto;
        word-break: break-word;
        width: calc(100% - 150px);
    }
}
