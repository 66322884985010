@import 'app/variables';

.suggestions {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: auto;
    margin-top: 6px;
    box-shadow: rgba(0, 0, 0, 0.07) 0 0 15px;
    text-align: left;
    background: #fff;
    border: 1px solid $neutralGray4;
    border-radius: 8px;
    z-index: 15;
    opacity: 0;
    transition: visibility 0.3s linear, opacity 0.3s linear;
    visibility: hidden;

    &.isVisible {
        opacity: 1;
        visibility: visible;
    }
}

.searchIcon {
    position: relative;
    top: -2px;
    opacity: 0.5;
}

.skeletonTitle {
    padding: 16px 16px;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    user-select: initial;
    color: $darkGray;
    font-size: 14px;
    line-height: 20px;
    display: grid;
    grid-template-columns: 25px 75px 1fr;
}

.skeletonTitleQuery {
    color: $neutralBlack;
    margin-left: 3px;
}

.dataContainer {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 340px;
    min-height: 0;
}

.showAllResults {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 16px 0;
    color: $primaryRoyalBlue;
    text-align: center;
    cursor: pointer;
}

.noResults {
    padding: 16px;
    cursor: pointer;
    text-align: center;
}

.noResultsIcon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    position: relative;
    top: 1px;

    path {
        stroke: $neutralGray3;
    }

    &:hover {
        path {
            stroke: $primaryRoyalBlue;
        }
    }
}

.noResultsText {
    color: $neutralGray3;
}

.suggestionCategoryName {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding-left: 16px;
}

.suggestionCategoryItems {
    margin-top: 8px;
}

.suggestionCategory {
    margin-top: 24px;

    &:first-child {
        margin-top: 16px;
    }
}

.selected {
    background: $neutralGray4;

    &:hover {
        background: $neutralGray4;
    }
}
