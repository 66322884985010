@import 'app/variables';

.container {
    position: relative;
    display: inline-block;
}

.button {
    border: none;
    background: none;
    display: inline-block;
    cursor: pointer;
    margin: 0;
    padding: 0;
}

.popup {
    position: absolute;
    background: $neutralWhite;
    box-shadow: 0 2px 24px rgba(45, 45, 45, 0.02), 0 4px 44px rgba(45, 45, 45, 0.06);
    border-radius: 8px;
    width: 320px;
    overflow: hidden;
    display: none;
    top: 100%;
    right: 0;
    z-index: 100;
    text-align: center;
}

.show.popup {
    display: block;
}

.background {
    height: 96px;
    background-image: url(../../../../../static/images/profile-bg.svg);
    background-size: cover;
    background-repeat: no-repeat;
}

.avatar {
    width: 72px;
    height: 72px;
    margin: -36px auto 16px;
}

.name {
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    color: $neutralGray1;
    margin-bottom: 16px;
    padding: 0 24px;
}

.jobInfo {
    font-size: 14px;
    line-height: 20px;
    color: $neutralGray2;
    margin-bottom: 8px;
    padding: 0 24px;
}

.companyName {
    font-size: 14px;
    line-height: 20px;
    color: $neutralGray1;
    padding: 0 24px;
}

.contact {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 30px;

    a {
        margin-right: 16px;
    }
}
