@import 'app/variables';
@import 'app/mixins';

$attachmentHeight: 54px;
$attachmentBottomMargin: 4px;

.attachmentsList {
    background: $neutralWhite;
    padding: 0 8px 16px 72px;
    width: auto;
    display: flex;
    flex-wrap: wrap;
    transition: 1s 0.5s;
}

.mailAttachment {
    font-size: 16px;
    border: 1px solid $neutralGray4;
    border-radius: 5px;
    margin: 0 8px 8px 0;
    width: 220px;
    display: flex;
    height: $attachmentHeight;
    flex-shrink: 0;
}

.mailAttachmentInfo {
    display: flex;
    width: calc(100% - 26px);
    flex: 1 1 auto;
    cursor: pointer;
}

.link {
    padding: 0 8px 16px 72px;
}

.thumbnail {
    font-size: 16px;
    margin: 8px;
}

.info {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    min-width: 0;
    margin: 8px 8px 0 0;
    width: 100%;
}

.name {
    line-height: 16px;
    font-size: 12px;

    @include text-ellipsis();
}

.size {
    line-height: 16px;
    font-size: 12px;

    @include text-ellipsis();
}

.actions {
    border-left: 1px solid $neutralGray4;
    position: relative;
}

.actionsButton {
    cursor: pointer;
    width: 24px;
    height: 100%;
    position: relative;
    background: none;
    border: none;

    &::before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border-right: 2px solid $neutralGray3;
        border-bottom: 2px solid $neutralGray3;
        transform: translate(-50%, -50%) rotate(45deg);
        position: absolute;
        top: 50%;
        left: 50%;
    }
}

.actionsPopup {
    position: absolute;
    display: none;
    right: 0;
    top: 75%;
    background: $neutralWhite;
    box-shadow: 1px 7px 15px 0 rgba(0, 0, 0, 0.13);
    padding: 10px 0;
    border-radius: 2px;
    z-index: 99999;

    &.open {
        display: block;
    }

    & > button {
        font-size: 14px;
        display: flex;
        padding: 8px 16px;
        cursor: pointer;
        background: none;
        border: none;
        width: 100%;

        span {
            color: $neutralBlack;
        }

        svg {
            margin-right: 8px;
        }

        &:hover {
            background: $neutralGray4;
        }

        &:disabled {
            cursor: not-allowed;
            color: $neutralGray2;

            path {
                stroke: $neutralGray2;
            }
        }
    }
}
