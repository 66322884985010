@import 'app/variables';

.ownerInformation {
    .ownerInformationTitle {
        font: 500 16px/24px 'ADNOC Sans', sans-serif;
        margin-bottom: 16px;
    }
}

.status {
    width: min-content;
    font: normal 10px/13px 'ADNOC Sans', sans-serif;
    padding: 2px 4px 0;
    border-radius: 4px;
    background: rgba(8, 155, 215, 0.15);
    color: #089bd7;

    &:not(:last-child) {
        margin-bottom: 4px;
    }
}
