@import 'app/variables';

.searchInput {
    flex-shrink: 0;
    display: flex;
    position: relative;
    align-items: center;
    max-width: 450px;
    width: 100%;
    margin: auto;

    @media ($mediaSmall) {
        margin-bottom: 16px;
    }
}

.input {
    width: 100%;
    height: 40px;
    font-size: 14px;
    padding-right: 68px;
    padding-left: 15px;
    border-width: 1px;
    border-style: solid;
    border-color: $neutralGray4;
    border-image: initial;
    border-radius: 32px;
    transition: opacity 0.3s ease-in-out 0s;
    outline: none;

    &:placeholder-shown {
        text-overflow: ellipsis;
    }

    &:focus {
        border-color: $primaryRoyalBlue;
    }
}

.icon {
    position: absolute;
    right: 2px;
    width: 37px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    visibility: visible;
    background-color: $neutralGray5;
    border-radius: 50%;
    background-image: url(../../../static/images/magnifyingGlass.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.clear {
    @extend .icon;

    right: 35px;
    background-image: url(../../../static/images/times.svg);
    background-color: transparent;

    &.closeShift {
        right: 67px;
    }
}

.guide {
    position: absolute;
    right: 46px;
}

.guideButton {
    width: 16px;
    height: 16px;
    border: 1px solid $neutralGray3;
    border-radius: 50%;
    cursor: pointer;

    &::before,
    &::after {
        content: '';
        display: block;
        background: $neutralGray3;
        width: 2px;
        border-radius: 1px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
    }

    &::before {
        height: 5px;
        top: 7px;
    }

    &::after {
        height: 2px;
        top: 4px;
    }

    &:hover {
        border: 1.5px solid $neutralGray1;
        &::before,
        &::after {
            background: $neutralGray1;
        }
    }
}

.closeButton {
    width: 16px;
    height: 16px;
    position: absolute;
    cursor: pointer;
    right: 16px;
    top: 16px;

    &::before,
    &::after {
        content: '';
        display: block;
        background: $neutralGray3;
        width: 18px;
        height: 2px;
        border-radius: 1px;
        position: absolute;
        top: 7px;
        left: -1px;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}

.guidePopup {
    position: absolute;
    left: 50%;
    top: calc(100% + 12px);
    transform: translate(-50%, 0);
    padding: 16px;
    text-align: left;
    background: $neutralWhite;
    font-size: 12px;
    box-shadow: 1px 7px 15px 0 rgba(0, 0, 0, 0.13);
    border-radius: 2px;

    &::before {
        content: '';
        display: block;
        background: $neutralWhite;
        width: 16px;
        height: 16px;
        position: absolute;
        left: 50%;
        top: -8px;
        border-left: 1px solid rgba(0, 0, 0, 0.01);
        border-top: 1px solid rgba(0, 0, 0, 0.01);
        transform: translate(-50%, 0) rotate(45deg);
    }

    @media ($mediaSmall) {
        left: auto;
        right: -46px;
        transform: none;

        &::before {
            left: auto;
            right: 36px;
        }
    }
}
