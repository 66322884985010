.searchPage {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.container {
    display: flex;
    flex-grow: 1;
    min-height: 0;
}

.contentWrapper {
    flex-direction: column;
    display: flex;
    width: 100%;
    overflow: hidden;
}

.content {
    display: flex;
    min-height: 0;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    position: relative;

    & > div {
        min-width: 0;
    }
}
