@import 'app/variables';
@import 'app/mixins';

.containerTitle {
    font: 500 16px/24px 'ADNOC Sans', sans-serif;
    margin-bottom: 16px;
}

.tableContainer {
    overflow-x: auto;
}

.table {
    position: relative;
    border-collapse: collapse;
    width: 100%;

    th,
    td {
        text-align: left;
        border-bottom: 1px solid $neutralGray4;
    }

    th {
        padding: 8px 16px;
        font: 500 12px/16px 'ADNOC Sans', sans-serif;
        background-color: $neutralGray6;
        white-space: nowrap;
    }

    td {
        padding: 10px 16px;
        font: 400 14px/20px 'ADNOC Sans', sans-serif;
        white-space: nowrap;

        &:first-of-type {
            width: 46%;
        }
    }
}

@media ($mediaSmall) {
    th,
    td {
        padding-left: 5px;
        padding-right: 11px;

        &:last-of-type {
            padding-right: 5px;
        }
    }
}
.table.smallContainerDesktop {
    th,
    td {
        padding-left: 5px;
        padding-right: 11px;

        &:last-of-type {
            padding-right: 5px;
        }
    }
}

.link {
    text-align: left;
    margin-top: 16px;
    color: $primaryRoyalBlue;
}
