.textFieldFilter {
    margin-bottom: 24px;
}

.textFieldEntryLabel {
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    padding-top: 8px;
    padding-bottom: 4px;
    display: inline-block;
}
.textFieldEntry:first-of-type {
    .textFieldEntryLabel {
        padding-top: 0;
    }
}

.fieldWrapper {
    position: relative;
}

.field {
    font-size: 14px;
    font-family: 'ADNOC Sans', sans-serif;
    line-height: 20px;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 10px 16px;
    width: 100%;

    &:focus {
        border: 1px solid #1859a9;
        outline: none;
    }
}
