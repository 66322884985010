@import 'app/variables';

.imageContainer {
    margin: auto;
    transform: translateY(-50%);
}

.supplierImage {
    background-image: url(../../../../static/images/news.svg);
    background-repeat: no-repeat;
    width: 100px;
    height: 103px;
    margin: auto auto 16px;
}

.noSelectedText {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $neutralGray1;
}

.skeleton {
    display: flex;
    justify-content: center;
}

.title {
    font: 500 18px/24px 'ADNOC Sans', sans-serif;
    margin: 0;
    margin-bottom: 16px;
    word-break: break-word;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr min-content min-content min-content min-content;
    align-items: center;

    @media (max-width: 620px) {
        grid-template-columns: min-content 1fr min-content;
        grid-template-rows: auto auto;

        & > *:first-child {
            grid-column: 1 / -1;
        }
        & > *:nth-child(3) {
            grid-column: 3;
        }
    }
}

.titleName {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contentWrapper {
    background: $neutralWhite;
    border: 1px solid $neutralGray6;
    box-sizing: border-box;
    box-shadow: 0 2px 24px rgba(45, 45, 45, 0.02), 0 4px 44px rgba(45, 45, 45, 0.06);
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 24px;
    overflow: hidden;

    @media ($mediaExtraSmall) {
        padding: 16px;
    }
}
.contentTitle {
    font: 500 16px/24px 'ADNOC Sans', sans-serif;
    margin: 0 0 16px 0;
}

.tabsWrapper {
    padding: 0;
}

.tabsMuiAppBarRoot {
    padding: 0;
    margin: 0 16px;
    width: auto;
}

.tabsContainer {
    width: 100%;
    background: $neutralWhite;
}

.mapBlockContent {
    display: inline-block;
    max-width: 100%;
}

.infoTable {
    display: grid;
    grid-template-columns: 140px 200px;
    gap: 16px 8px;

    @media ($mediaSmall) {
        margin-bottom: 24px;
    }

    .infoTableValue,
    .infoTableTitle {
        font: normal 14px/20px 'ADNOC Sans', sans-serif;
    }
    .infoTableTitle {
        color: $neutralGray2;
    }
    .infoTableValue {
        color: $neutralBlack;
    }
}

.supplierLink {
    color: $primaryRoyalBlue;
    &:not(:hover) {
        color: inherit;
        text-decoration: none;
    }
}

.docIcon {
    cursor: pointer;
    width: 32px;
    height: 32px;
}

.documentPreviewContainer {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed !important;
    z-index: 99999;
}

.documentPreview {
    width: 100%;
    height: 100%;
}

.loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $neutralWhite;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $neutralWhite;
    display: flex;
    align-items: center;
    justify-content: center;
}
