.app {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
}

.appLayout {
    display: flex;
    flex-direction: column;
    height: 100%;
}
