@import 'variables.scss';

@mixin text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
}

@mixin n-lines-text-overflow($line-height: 20px, $lines: 2) {
    display: inline-block;
    display: -webkit-box;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    max-height: $line-height * $lines;
    line-height: $line-height;
}

@mixin max-100-overflow {
    overflow: auto;
    max-height: 100%;
}

@mixin scroll-bar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $darkGray;
    }
}

@mixin icon($width: 16px, $height: 16px) {
    background-size: contain;
    background-repeat: no-repeat;
    flex-grow: 0;
    flex-shrink: 0;
    display: inline-block;
    width: $width;
    height: $height;
}
