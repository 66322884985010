@import 'app/variables';
@import 'app/mixins';

.header {
    margin-bottom: 16px;

    h3 {
        font: 500 16px/24px 'ADNOC Sans', sans-serif;
        padding: 0;
        margin: 0 8px 0 0;
        display: inline-block;
    }

    .labelsContainer {
        display: inline-block;

        & > * {
            margin-right: 8px;
        }
    }
}

.tableWrapper {
    overflow: auto;
    max-height: 320px;
}

.table {
    border-collapse: collapse;
    width: 100%;

    th {
        padding: 8px 8px 7px;
        background-color: $neutralGray6;
        font: 500 12px/16px 'ADNOC Sans', sans-serif;
        position: sticky;
        white-space: nowrap;
        top: 0;
        border-bottom: 1px solid $neutralGray4;
        text-align: left;
        z-index: 100;
    }

    td {
        padding: 10px 8px;
        font: 400 14px/20px 'ADNOC Sans', sans-serif;
        vertical-align: top;
        border-bottom: 1px solid $neutralGray4;
        position: relative;

        .text {
            min-width: 160px;
            @include n-lines-text-overflow(20px, 3);
        }

        .amount {
            white-space: nowrap;
        }

        &.clear {
            border-top: none;
        }
    }
}

.row {
    cursor: pointer;

    &:hover {
        background: $neutralGray5;
    }

    td:first-child {
        padding-left: 28px;
        &::before {
            content: '';
            display: block;
            border: 2px solid $neutralGray3;
            border-top: none;
            border-left: none;
            width: 7px;
            height: 7px;
            position: absolute;
            left: 10px;
            top: 15px;
            transform: rotate(-45deg);
            transition: transform linear 0.1s;
        }
    }
}

.expand {
    background: $neutralGray5;

    td:first-child::before {
        transform: rotate(45deg);
    }
}
