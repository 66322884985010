@import 'app/variables';

.mailPreviewPanel {
    display: flex;
    flex-direction: column;
    height: 100%;

    @media ($mediaSmall) {
        padding: 0;
    }
}

.title {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 3px;
    padding: 0 8px;
}

.iframeContainer {
    flex-grow: 1;
    min-height: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: 0;
}

.imageContainer {
    margin: auto;
    transform: translateY(-50%);
}

.mailImage {
    background-image: url(../../../../static/images/pleaseChooseEmail.svg);
    width: 104px;
    height: 128px;
    margin: auto auto 16px;
}

.noSelectedText {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $neutralGray1;
}

.skeleton {
    display: flex;
    justify-content: center;
}

.loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $neutralWhite;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $neutralWhite;
    display: flex;
    align-items: center;
    justify-content: center;
}

.documentPreviewContainer {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed !important;
    z-index: 99999;
}

.documentPreview {
    width: 100%;
    height: 100%;
}
