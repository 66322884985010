@import 'app/variables';

.skeleton {
    display: flex;
}
.avatar {
    margin-right: 16px;
    flex: 0 0 auto;
}
.infoPanel {
    flex-shrink: 0;
}
.info {
    margin-bottom: 8px;
}
.info:last-child {
    margin: 0;
}
