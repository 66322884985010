@import 'app/variables';
@import 'app/mixins';

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.company {
    font-size: 12px;
    line-height: 16px;
}

.serialNumber {
    margin-right: 0;
}

.labelWrapper {
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 16px -4px -4px -4px;
    & > * {
        margin: 0 4px 4px;
    }
}

.projectManager {
    font-size: 14px;
    line-height: 20px;
    display: flex;
}

.thumbnail {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.title {
    @include n-lines-text-overflow(20px, 2);

    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin-top: 4px;
}

.date {
    color: $neutralGray2;
    font-size: 12px;
}
