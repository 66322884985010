.item {
    & > * {
        margin-bottom: 8px;
    }
}
.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
