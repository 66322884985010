@import 'app/variables';
@import 'app/mixins';

.title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    h2 {
        font-size: 16px;
        margin: 0 0 20px;
    }
}

.date {
    height: 32px;
    font-size: 14px;
    line-height: 14px;
    padding: 8px 14px;
    background: $neutralWhite;
    border: 1px solid $neutralGray4;
    box-sizing: border-box;
    border-radius: 5px;
    width: 170px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        height: 16px;
        margin-left: 14px;
    }
}

.container {
    flex-grow: 1;
    padding: 26px 32px;
    overflow: auto;

    .content {
        max-width: 1016px;
        margin: 0 auto;
    }

    @media ($mediaSmall) {
        padding: 16px;
    }
}

.itemContainer {
    background: $neutralWhite;
    box-shadow: 0 2px 24px rgba(45, 45, 45, 0.02), 0 4px 44px rgba(45, 45, 45, 0.06);
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;

    @media ($mediaExtraSmall) {
        padding: 16px;
    }

    h3 {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 16px;
    }

    .containerTitle {
        display: flex;
        align-items: baseline;
        margin-bottom: 10px;
        flex-wrap: wrap;

        h3 {
            flex: 1 1 auto;
        }
    }

    & > h4 {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        margin: 0 0 16px;
    }

    & > p {
        font-size: 14px;
        line-height: 24px;
        padding: 0;
        margin: 0;
        white-space: pre-line;
    }
}

.twoColumns,
.threeColumns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -8px;

    & > * {
        margin-left: 8px;
        margin-right: 8px;
        width: calc(50% - 16px);
    }
}

.twoColumns {
    & > * {
        width: calc(50% - 16px);
    }
}

.threeColumns {
    & > * {
        width: calc(33.333% - 16px);
    }
}

.small {
    h2 {
        margin-bottom: 16px;
    }

    .title {
        display: block;
    }

    .tabsContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 14px;
        margin-top: 7px;
    }
}
