@import 'app/variables';

.table {
    position: relative;
    font: normal 12px/16px 'ADNOC Sans', sans-serif;

    .tableRow {
        margin-bottom: 12px;

        &:nth-child(3) {
            margin-bottom: 0;
            padding-top: 12px;
            border-top: 1px solid $neutralGray4;
        }
    }

    .tableRowHeader,
    .titleHeaderAdditional {
        font-weight: 700;
        color: $neutralGray1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .tableRowHeader {
        margin-bottom: 4px;
    }

    .titleHeaderAdditional {
        display: none;
    }

    .tableValues {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @media (min-width: 380px) and (max-width: 450px), (min-width: 684px) and ($mediaSmall) {
        .tableRowHeader {
            display: none;
        }

        .tableValues {
            display: grid;
            grid-template-columns: calc(35% - 6px) calc(20% - 12px) calc(45% - 6px);

            * {
                text-align: right;
            }
        }

        .titleHeaderAdditional {
            display: block;
            text-align: left;
        }
    }
}
