@import 'app/variables';

$topBarHeight: 96px;

.topBar {
    flex-shrink: 0;
    width: 100%;
    height: $topBarHeight;
    border-top: 8px solid #0074f8;
    border-bottom: 1px solid #eee;
    text-align: center;
    padding: 0 24px;
    font-size: 30px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    z-index: 2000;

    @media ($mediaSmall) {
        padding: 0 16px;
        height: auto;
    }
}
